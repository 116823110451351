import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { TELEGRAM_CHANNEL_URL, STORE_NAME } from "../../../config";

const Question = ({ question, answer }) => {
    const [questionOpen, setQuestionOpen] = useState(false);

    return (
        <div className={`faq-question ${questionOpen === true ? "faq-question-expanded" : ""}`} onClick={() => setQuestionOpen(!questionOpen)}>
            <div className={`faq-question-title ${questionOpen === true ? "faq-question-title-expanded" : ""}`}>
                <div className="faq-question-name">{question}</div>
                <div className="faq-question-icon"><FontAwesomeIcon icon={questionOpen === true ? faMinus : faPlus} /></div>
            </div>
            <div className="faq-question-content" style={{ display: questionOpen === true ? "block" : "none" }}>
                {answer}
            </div>
        </div>
    )
};

const FAQ = () => {
    const questions = [
        {
            question: "How to create an account?",
            answer: <div>
                <ul>
                    <li>Go to <Link to="/user/register">Sign Up</Link></li>
                    <li>Enter your desired username, email and password</li>
                    <li>Solve any required captcha (if you see any)</li>
                    <li>Press the sign-up button</li>
                </ul>
            </div>
        },
        {
            question: "How to login to my account?",
            answer: <div>
            <ul>
                <li>Go to <Link to="/user/login">Login</Link></li>
                <li>Enter your previously chosen email and password</li>
                <li>Solve any required captcha (if you see any)</li>
                <li>Press the login button</li>
            </ul>
        </div>
        },
        {
            question: "Can I order as a guest?",
            answer: <div>Yes, you can create an order without an account. However, we highly recommend you sign up to take advantage of the benefits of using the site while logged in, such as our ticket-based support system, on-site balance for instant order delivery and more</div>
        },
        {
            question: "How to order products from the store?",
            answer: <div>
                <ul>
                    <li>Go to the <Link to="/">Shop</Link></li>
                    <li>Find the products you'd like to order</li>
                    <li>On each product, you can chose the quantity to add to your cart</li>
                    <li>Select the add to cart option at the bottom of the popup to add the product to your cart</li>
                    <li>Go to your cart (Cart button at the top-right)</li>
                    <li>If your ordering as a guest, enter your email at checkout. Ensure that you can access this email, as a link to view your products will be sent to it</li>
                    <li>Enter a coupon code in the relevant box at checkout if you have one</li>
                    <li>Choose the payment method you'd like to use</li>
                    <li>Check the "Use account balance" checkbox if your logged in and would like to use your available account balance</li>
                    <li>Press the checkout button</li>
                    <li>Follow the on-screen instructions to complete your payment</li>
                    <li>After you've completed your payment, you will be prestened with the delivered product on-screen, a link will be sent to your email to login and view your product</li>
                </ul>
            </div>
        },
        {
            question: "How to get support?",
            answer: <div>
                We have an easy to use ticket-based support system. You can easily create a ticket on our user dashboard and our worldwide support team will resolve your issue as soon as possible
            </div>
        },
        {
            question: `How to stay updated about ${STORE_NAME}?`,
            answer: <div>
                We highly recommend you join our <Link to={TELEGRAM_CHANNEL_URL}>Telegram</Link> channel to stay updated. We regularly post stock information, coupon codes and more
            </div>
        }
    ];

    return (
        <div className="storefront-faq">
            <div className="faq-title">
                <div className="user-home-welcome-title">Frequently Asked Questions</div>
                <div className="user-home-welcome-text">View answers to some of our most frequently asked questions</div>
            </div>
            <div className="faq-questions">
                {questions.map((question, index) => (
                    <Question key={`faq-question-${index}-${Math.random.toString()}`} question={question.question} answer={question.answer} index={`faq-question-${index}`} />
                ))}
            </div>
        </div>
    )
};

export default FAQ;