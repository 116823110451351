import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faEnvelope, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { API_URL, STORE_NAME } from "../../../config";

const TicketSendMessage = ({ messageContent, setMessageContent, sendTicketMessage }) => {
    return (
        <div className="userticket-send-message">
            <div className="userticket-message-title">Send a new message to your ticket</div>
            <div className="userticket-message-text">Please include as much detail as possible so that we can solve your query efficiently</div>
            <textarea rows={5} defaultValue={messageContent} onChange={(e) => setMessageContent(e.target.value)} />
            <div className="userticket-send-message-options">
                <button className="btn btn-nrm" onClick={() => sendTicketMessage()}>Send Message</button>
            </div>
        </div>
    )
};

const UserViewTicket = () => {
    const [ticketClosed, setTicketClosed] = useState(false);
    const [ticketMessages, setTicketMessages] = useState(null);
    const [messageContent, setMessageContent] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    const getTicketId = () => {
        let tId = window.location.href.split("/tickets/")[1];
        return tId.includes("/") ? tId.split("/")[0] : tId;
    };

    const fetchData = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/user/dashboard/tickets/${getTicketId()}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setTicketMessages(data.ticket.messages);
            setTicketClosed(data.ticket.ticket_closed);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    const sendTicketMessage = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/user/dashboard/tickets/${getTicketId()}/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    content: messageContent
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setMessageContent(null);
            fetchData();
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };
    
    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading fullPage={true} />;
    
    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title"><FontAwesomeIcon icon={faEnvelope} /> Tickets <FontAwesomeIcon icon={faArrowRightLong} /> <span className="text-underline">{getTicketId()}</span></div>
            </div>

            <div className="userticket-messages">
                {ticketMessages.map((message, index) => {
                    return (
                        <div key={`userticket-message-${index}-${Math.random.toString()}`} className={`userticket-message-container ${message.from === "staff" ? "userticket-message-container-staff" : "userticket-message-container-user"}`}>
                            <div className={`userticket-message-content ${message.from === "staff" ? "userticket-message-content-staff" : "userticket-message-content-user"}`}>
                                <div className={`userticket-message ${message.from === "staff" ? "userticket-message-staff" : "userticket-message-user"}`}>
                                    {message.content.split("\n").map(s => <div key={`userticket-text-line-${index}-${Math.random.toString()}`} className="ticket-text-line">{s}</div>)}
                                </div>
                                <div className="userticket-message-info-text">{message.from === "staff" ? `${STORE_NAME} Support` : "You"} • {new Date(message.created_at).toLocaleString()}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {ticketClosed === true
                ? <div className="ticket-closed-alert"><FontAwesomeIcon icon={faExclamationCircle} />This ticket is closed, and you can no longer reply to it. If you need more help, please <Link to="/user/tickets/new">open a new ticket</Link></div>
                : <TicketSendMessage messageContent={messageContent} setMessageContent={setMessageContent} sendTicketMessage={sendTicketMessage} />
            }
        </div>
    )
};

export default UserViewTicket;