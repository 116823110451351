import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Error = ({ error, alert }) => {
    return (
        <div className="storefront-error">
            {alert !== true ? <div className="storefront-error-icon"><FontAwesomeIcon icon={alert === true ? faInfoCircle : faCircleExclamation} /></div> : null}
            {alert !== true ? <div className="storefront-error-title">An error occurred!</div> : null}
            <div className="storefront-error-text">{error}</div>
        </div>
    )
};

export default Error;