import { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircleQuestion, faCoins, faArrowRightLong, faRightFromBracket, faUser, faEnvelope, faDollarSign, faNewspaper, faKey, faExternalLink, faExternalLinkSquare, faExternalLinkAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../../contexts/user";
import { API_URL, STORE_NAME } from "../../../config";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

const paymentTags = {
    "completed": "Paid",
    "processing": "Paid",
    "awaiting_payment": "Unpaid",
    "cancelled": "Cancelled"
};

Modal.setAppElement("#root");

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999'
    },
    overlay: {
        zIndex: "9999"
    }
};

const HeroItem = ({ icon, name, value, to }) => {
    const navigate = useNavigate();

    return (
        <div className="userhome-hero-item" onClick={() => navigate(to)}>
            <div className="userhome-hero-icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="userhome-hero-content">
                <div className="userhome-hero-title">{name}</div>
                <div className="userhome-hero-value">{value}</div>
            </div>
        </div>
    )  
};

const TelegramLink = () => {
    const [telegramModalOpen, setTelegramModalOpen] = useState(false);
    const [code, setCode] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const copyText = (text) => {
        var input = document.createElement('textarea');
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    };

    useEffect(() => {
        const fetchData = async() => {
            try {
                const request = await fetch(`${API_URL}/user/dashboard/telegram/code`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await request.json();
                if(!data.success) {
                    if(request.status === 403) return navigate("/user/login");
                    setError(data.response || data.message);
                    setLoaded(true);
                    return;
                };

                setCode(data.code);
                setLoaded(true);
            } catch(e) {
                console.log(e);
                setError(error);
            };
        };
        fetchData();
    }, []);

    if(loaded === false) return <StorefrontLoading fullPage={false} />;

    return (
        <>
            <div className="user-alert">
                <div className="user-alert-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"/></svg>
                </div>
                <div className="user-alert-content">
                    <div className="user-alert-title">{STORE_NAME} x Telegram</div>
                    <div className="user-alert-text">Connect your telegram account to your {STORE_NAME} account for discounts, order & support notifications and more</div>
                </div>
                <button className="btn btn-snb" onClick={() => setTelegramModalOpen(true)}>Connect</button>
            </div>

            <Modal
                isOpen={telegramModalOpen}
                onRequestClose={() => setTelegramModalOpen(false)}
                style={modalStyle}
                id={"telegram-connect-modal"}
            >
                {error !== false ? <div className="auth-form-error">{error}</div> : null}
                <div className="storefront-modal-title">Connect your Telegram account</div>
                <div className="storefront-modal-description">Follow the simple steps below to connect your telegram account</div>
                
                <div className="cnt-step">
                    <div className="cnt-step-title">Step 1: Copy the command below</div>
                    <div className="cnt-step-text">This is a unique code that proves to our telegram bot you own this {STORE_NAME} account</div>

                    <div className="cnt-code">
                        <div className="cnt-code-txt">/weblink {code}</div>
                        <button className="btn btn-snb cnt-cpy-btn" onClick={() => copyText(`/weblink ${code}`)}><FontAwesomeIcon icon={faCopy} />Copy</button>
                    </div>
                </div>

                <div className="cnt-step">
                    <div className="cnt-step-title">Step 2: Message @FatFoBot on Telegram</div>
                    <div className="cnt-step-text">Send the command you copied above to our telegram bot</div>

                    <button className="btn btn-snb cnt-btn" onClick={() => window.open("https://t.me/fatfobot", "_blank")}><FontAwesomeIcon icon={faExternalLink} />Open Telegram</button>
                </div>
            </Modal>
        </>
    )
};

const UserValue = ({ icon, title, value }) => {
    return (
        <div className="user-home-vls">
            <div className="uh-vls-title">{title}</div>
            <div className="uh-vls-value">{value}</div>
        </div>
    )
};

const OrdersTable = ({ orders }) => {
    const naviagte = useNavigate();
    
    return (
        <table className="userorders-table mt-2">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th style={{ textAlign: "right" }}>Action</th>
                </tr>
             </thead>
            <tbody>
                {orders.map((order, index) => (
                    <tr key={`order-table-value-${index}-${Math.random.toString()}`}>
                        <td>{order.title}</td>
                        <td>${order.total.toFixed(2)}</td>
                        <td><div className={`userorders-table-status userorders-table-status-${order.status.toLowerCase()}`}>{paymentTags[order.status]}</div></td>
                        <td style={{ textAlign: "right" }}><button className="btn btn-nrm btn-nrm-sm" onClick={() => naviagte(`/orders/${order.order_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const UserHome = () => {
    const { user, setUser } = useContext(UserContext);
    const [userId, setUserId] = useState(null);
    const [userCreated, setUserCreated] = useState(null);
    const [userTotalOrders, setUserTotalOrders] = useState(0);
    const [recentOrders, setRecentOrders] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const naviagte = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const dataResponse = await fetch(`${API_URL}/user/dashboard/home`, {
                    credentials: "include"
                });
                if(dataResponse.status === 403) return naviagte("/user/login");
                const data = await dataResponse.json();
                if(!data.success) {
                    setLoaded(true);
                    setError(data.response || data.message);
                    return;
                };

                setUserId(data.user.user_id);
                setRecentOrders(data.user.recent_orders);
                setUserCreated(data.user.created_at);
                setUserTotalOrders(data.user.total_orders);
                setLoaded(true);
            } catch(e) {
                console.log(e);
                setError("An unknown error occurred, please try again later or contact support if the issue persists");
                setLoaded(true);
            };
        };
        fetchData();
    }, []);

    if(error !== false) return <StorefrontError error={error} />;
    if(!loaded) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title">Welcome, {user.username}</div>
            </div>
            {/* {!telegramLinked ? <TelegramLink /> : null} */}
            <div className="user-home-hero">
                <div className="user-home-hero-content">
                    <div className="user-home-details-title">User Details</div>
                    <div className="user-home-hero-vls-container">
                        <UserValue title="ID" value={userId} />
                        <UserValue title="Username" value={user.username} />
                        <UserValue title="Email" value={user.email} />
                        <UserValue title="Balance" value={<span className="grn">${user.balance.toFixed(2)}</span>} />
                        <UserValue title="Joined" value={`${new Date(userCreated).toLocaleString()}`} />
                        <UserValue title="Orders" value={userTotalOrders} />
                    </div>
                    <div className="user-home-hero-options">
                        <button className="btn user-home-hero-btn-lg" onClick={() => naviagte("/user/settings")}>Account Settings</button>
                        <button className="btn user-home-hero-btn-lgout" onClick={() => naviagte("/user/logout")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="vuesax/bulk/logout">
                                    <g id="logout">
                                        <path id="Vector" opacity="0.4" d="M13.2402 2C13.7102 2 14.1002 2.38 14.1002 2.86V21.15C14.1002 21.62 13.7202 22.01 13.2402 22.01C7.35023 22.01 3.24023 17.9 3.24023 12.01C3.24023 6.12 7.36023 2 13.2402 2Z" fill="white"/>
                                        <path id="Vector_2" d="M20.5399 11.54L17.6999 8.69003C17.4099 8.40003 16.9299 8.40003 16.6399 8.69003C16.3499 8.98003 16.3499 9.46003 16.6399 9.75003L18.1999 11.31H8.62988C8.21988 11.31 7.87988 11.65 7.87988 12.06C7.87988 12.47 8.21988 12.81 8.62988 12.81H18.1999L16.6399 14.37C16.3499 14.66 16.3499 15.14 16.6399 15.43C16.7899 15.58 16.9799 15.65 17.1699 15.65C17.3599 15.65 17.5499 15.58 17.6999 15.43L20.5399 12.58C20.8299 12.3 20.8299 11.83 20.5399 11.54Z" fill="white"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="user-home-recent-orders">
                    <div className="user-home-recent-orders-title">
                        <div className="user-home-recent-orders-title-text">Recent Orders</div>
                        <div className="user-home-recent-orders-title-link" onClick={() => naviagte("/user/orders")}>
                            View All Orders
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Icon" d="M7.71967 1.53033C7.42678 1.23744 7.42678 0.762563 7.71967 0.46967C8.01256 0.176777 8.48744 0.176777 8.78033 0.46967L12.7803 4.46967C13.0732 4.76256 13.0732 5.23744 12.7803 5.53033L8.78033 9.53033C8.48744 9.82322 8.01256 9.82322 7.71967 9.53033C7.42678 9.23744 7.42678 8.76256 7.71967 8.46967L10.4393 5.75H0.75C0.335787 5.75 0 5.41421 0 5C0 4.58579 0.335787 4.25 0.75 4.25H10.4393L7.71967 1.53033Z" fill="#1675FF"/>
                            </svg>
                        </div>
                    </div>
                    <OrdersTable orders={recentOrders} />
                </div>
            </div>
        </div>
    )
};

export default UserHome;