import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";
import { API_URL } from "../../../config";  

const paymentTags = {
    "completed": "Paid",
    "processing": "Paid",
    "awaiting_payment": "Unpaid",
    "cancelled": "Cancelled"
};

const OrdersTable = ({ orders }) => {
    const naviagte = useNavigate();

    return (
        <div className="userorders-table-lg">
            <table className="userorders-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Total</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th style={{ textAlign: "right" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => (
                        <tr key={`order-table-value-${index}-${Math.random.toString()}`}>
                            <td>{index + 1}</td>
                            <td>{order.title}</td>
                            <td>${order.total.toFixed(2)}</td>
                            <td>{new Date(order.created_at).toLocaleString()}</td>
                            <td><div className={`userorders-table-status userorders-table-status-${order.status.toLowerCase()}`}>{paymentTags[order.status]}</div></td>
                            <td style={{ textAlign: "right" }}><button className="btn btn-nrm btn-nrm-sm" onClick={() => naviagte(`/orders/${order.order_id}`)}>View</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [displayedOrders, setDisplayedOrders] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [filterStatus, setFilterStatus] = useState("all");
    const [dropdownColorClass, setDropdownColorClass] = useState("option-all");

    const filterByTitle = (e) => {
        if(!(e.target.value.length > 0)) setDisplayedOrders(orders);
        setDisplayedOrders(orders.filter(o => o.title.toLowerCase().includes(e.target.value.toLowerCase())));
    };

    const filterByStatus = (e) => {
        setDropdownColorClass(`option-${e.target.value}`);
        setDisplayedOrders(orders.filter(o => o.status === e.target.value));
    };

    useEffect(() => {
        const fetchData = async() => {
            try {
                const data = await fetch(`${API_URL}/user/dashboard/orders`, {
                    method: "GET",
                    credentials: "include"
                }).then(r => r.json());
                if(!data.success) {
                    setError(data.response || data.message);
                    setLoaded(true);
                    return;
                };
                setOrders(data.orders);
                setDisplayedOrders(data.orders);
                setLoaded(true);
            } catch(e) {
                setError("An unknown error occurred, please try again later!");
            }
        };
        fetchData();
    }, []);
    
    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-orders-header">
                <div className="user-orders-header-text">Your Orders</div>

                <div className="user-orders-header-options">
                    <div className="storefront-input-container storefront-input-container-sm">
                        <div className="storefront-input-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3849 15.446C11.7346 17.5687 7.8552 17.4016 5.39842 14.9448C2.76238 12.3088 2.76238 8.03494 5.39842 5.3989C8.03445 2.76287 12.3083 2.76287 14.9444 5.3989C17.4011 7.85569 17.5682 11.7351 15.4456 14.3854L20.6012 19.541C20.8941 19.8339 20.8941 20.3088 20.6012 20.6017C20.3083 20.8946 19.8334 20.8946 19.5405 20.6017L14.3849 15.446ZM6.45908 13.8842C4.40882 11.8339 4.40882 8.50982 6.45908 6.45956C8.50933 4.40931 11.8334 4.40931 13.8837 6.45956C15.9324 8.50831 15.9339 11.8291 13.8882 13.8797C13.8867 13.8812 13.8852 13.8827 13.8837 13.8842C13.8822 13.8857 13.8807 13.8872 13.8792 13.8887C11.8286 15.9344 8.50783 15.9329 6.45908 13.8842Z" fill="white"/>
                            </svg>
                        </div>
                        <input className="storefront-input userorders-input" placeholder="Search for orders here" onChange={(e) => filterByTitle(e)} />
                    </div>

                    <div className="user-orders-filter-status-container">
                        <div className="user-orders-filter-status-text">Filter by</div>
                        <select className={`input storefront-input user-orders-filter-input ${dropdownColorClass}`} onChange={(e) => filterByStatus(e)}>
                            <option value="all">All</option>
                            <option value="completed">Paid</option>
                            <option value="awaiting_payment">Unpaid</option>
                            <option value="processing">Processing</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>
                </div>
            </div>
            <OrdersTable orders={displayedOrders} />
        </div>
    );
};

export default Orders;