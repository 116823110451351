import { STORE_NAME } from "../../../config";

const Terms = () => {
    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title">Terms Of Service</div>
            </div>
            <div className="terms-content">
                <div className="terms-text">
                    As a buyer of {STORE_NAME}'s products and services, it is your obligation and responsibility to read the terms of service in their entirety. By reading, conceptualizing, and accepting such terms you are further bound by such terms and no exceptions will be afforded to you.
                </div>
                <div className="terms-list mt-2">
                    <div className="terms-list-title">General Terms And Conditions</div>
                    <ul className="terms-list-b mt-2">
                        <li>You must not change any information on the account. (name/email/password). Unless specified in the account description. If changed, warranty will be voided</li>
                        <li>No Refunds. If an account doesn't work, contact Live Chat within 3 hours of purchase date to exchange a non-working account into a valid account</li>
                        <li>If an account stops working after the 3 hours, {STORE_NAME} and any parties associated are not entitled to replace it</li>
                        <li>All sales are final</li>
                        <li>{STORE_NAME} and any parties associated are not responsible for any buyer conduct after purchase</li>
                    </ul>
                </div>
                <div className="terms-list mt-2">
                    <div className="terms-list-title">Pertinent Information Regarding Your Privacy</div>
                    <div className="terms-text mt-2">
                        Most credit card processors require a bank issued card that is linked to a pertinent personal information including but not limited to your name, address, SSN, DOB, and others that compromise your privacy and anonymity. If this is of importance to you, you must use cryptocurrency and a secure email such as Gmail during checkout. To protect all participating parties of said transaction(s), no temporary emails shall be used as an acceptable vessel of delivery for any products purchased from {STORE_NAME}. You must have access to said email that you stipulated for use. {STORE_NAME} and all parties within waives all liability regarding email accessibility or compatibility from products purchased.
                    </div>
                </div>
                <div className="terms-list mt-2">
                    <div className="terms-list-title">Expectations and Proper Conduct</div>
                    <ul className="terms-list-b mt-2">
                        <li>Once the transaction is complete, the product(s) will be delivered to the stipulated email during the checkout process. Once received it is the buyers obligation and responsibility to use the account immediately and report any significant discrepancy via DM to Live Chat on site</li>
                        <li>There is a 3 hour warranty on said products. Please use simple concise language and send photo documentation if possible</li>
                        <li>You will only be compensated the deficient amount. It is imperative to take note the warranty exclusively pertains to the account of said product</li>
                        <li>{STORE_NAME} and said parties are not liable for the usage, stores acceptance or any other particular regarding the product</li>
                        <li>ALL SALES ARE FINAL. {STORE_NAME} does not exchange based on buyers remorse. Albeit there is a strict NO REFUND policy, there may be very rare instances pertaining to Acts of God that warrant such and will be at the sole discretion of {STORE_NAME}</li>
                        <li>Any action or inaction where the buyer commits misrepresentation, deception, or ill intent against {STORE_NAME} or any parties associated shall warrant an immediate void in all liability and responsibility. It shall also deem you ineligible to be a customer going forward here within. You shall be BANNED from any groups owned by, associated, affiliated with, any sister sites, or partners thereof {STORE_NAME} and all parties associated of</li>
                        <li>Banned buyers are irreversible. Be cognizant of your status and the terms of service before begin a transaction. No product or refund will be given if such act is committed. If a buyer chooses to circumvent the system with various emails and such devious acts, no refunds will be issued and IP and mac address blocks will be placed</li>
                        <li>Any issues must be addressed through support in a respectful concise manner. The issue must pertain to {STORE_NAME} products that are for sale on the shop that you personally purchased. No tutorials are given through support, buyers are responsible for obtaining the knowledge to use said products. Intelligent questions are acceptable pertaining to your personal purchase</li>
                    </ul>
                </div>
                <div className="terms-list mt-2">
                    <div className="terms-list-title">Legal</div>
                    <div className="terms-text mt-2">The goods and products from this proposed store are for educational and promotional use only. They impose reality and are not intended to be used as such. No illegal means are intended. {STORE_NAME} and all parties associated rescind all liability regarding the buyers usage going</div>
                </div>
            </div>
        </div>
    );
};

export default Terms;