import { useContext, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Turnstile } from "@marsidev/react-turnstile";
import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY } from "../../../config";
import UserContext from "../../../contexts/user";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(false);
    const { setUser } = useContext(UserContext);
    const captchaRef = useRef();
    const navigate = useNavigate();

    const submit = async() => {
        try {
            const data = await fetch(`${API_URL}/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email,
                    password,
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCaptcha(null);
                setError(data.response || data.message);
                captchaRef.current?.reset();
                return;
            };
            
            setUser({
                loggedin: true,
                username: data.user.username,
                email: data.user.email,
                balance: data.user.balance
            });
            Cookies.set("loggedin", "true");
            Cookies.set("username", data.user.username);
            Cookies.set("email", data.user.email);
            Cookies.set("balance", data.user.balance);
            navigate("/user/home");
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    return (
        <div className="auth-form-page">
            <div className="auth-form">
                <div className="auth-form-title">Welcome back</div>
                <div className="auth-form-subtitle">It's good to see you again</div>

                {error !== false ? <div className="auth-form-error">{error}</div> : null}

                <div className="auth-form-inputs">
                    <div className="auth-input">
                        <div className="auth-input-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="vuesax/bulk/user-square">
                                    <g id="user-square">
                                        <path id="Vector" opacity="0.4" d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z" fill="white"/>
                                        <path id="Vector_2" d="M18.4401 21.66C17.7801 21.89 17.0201 22 16.1901 22H7.81006C6.98006 22 6.22006 21.89 5.56006 21.66C5.91006 19.02 8.67006 16.97 12.0001 16.97C15.3301 16.97 18.0901 19.02 18.4401 21.66Z" fill="white"/>
                                        <path id="Vector_3" d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z" fill="white"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <input className="auth-input-field" placeholder="Email" type="text" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="auth-input">
                        <div className="auth-input-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="vuesax/bulk/lock">
                                    <g id="lock">
                                        <path id="Vector" d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z" fill="white"/>
                                        <path id="Vector_2" opacity="0.4" d="M22 15V17C22 21 21 22 17 22H7C3 22 2 21 2 17V15C2 11.66 2.7 10.41 5.25 10.1C5.69 10.04 6.19 10.01 6.75 10H17.25C17.81 10.01 18.31 10.04 18.75 10.1C21.3 10.41 22 11.66 22 15Z" fill="white"/>
                                    <g id="Group">
                                        <path id="Vector_3" d="M8 17.0001C7.87 17.0001 7.74 16.9701 7.62 16.9201C7.49 16.8701 7.39001 16.8001 7.29001 16.7101C7.11001 16.5201 7 16.2601 7 16.0001C7 15.8701 7.02999 15.7401 7.07999 15.6201C7.12999 15.5001 7.20001 15.3901 7.29001 15.2901C7.39001 15.2001 7.49 15.1301 7.62 15.0801C7.99 14.9201 8.42999 15.0101 8.70999 15.2901C8.79999 15.3901 8.87001 15.5001 8.92001 15.6201C8.97001 15.7401 9 15.8701 9 16.0001C9 16.2601 8.88999 16.5201 8.70999 16.7101C8.51999 16.8901 8.26 17.0001 8 17.0001Z" fill="white"/>
                                    </g>
                                    <g id="Group_2">
                                        <path id="Vector_4" d="M12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17Z" fill="white"/>
                                    </g>
                                    <g id="Group_3">
                                        <path id="Vector_5" d="M16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71C16.52 16.89 16.26 17 16 17Z" fill="white"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </div>
                        <input className="auth-input-field" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>

                <div className="auth-form-captcha">
                    <Turnstile siteKey={CLOUDFLARE_TURNSTILE_SITEKEY} ref={captchaRef} onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} />
                </div>

                <button className="btn auth-form-btn" disabled={captcha === null} onClick={() => submit()}>Log In</button>
                <div className="auth-form-sub" style={{ marginTop: "12px" }}><Link to="/user/passwordreset">Forgot Password</Link></div>
            </div>

            <div className="auth-form-blw">
                <div className="auth-form-sub"><span>Don't have an account?</span> <Link to="/user/register">Create Account</Link></div>
            </div>
        </div>
    );
};

export default Login;