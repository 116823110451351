import { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import UserContext from "../../../contexts/user";
import { API_URL, STORE_NAME } from "../../../config";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

const BalanceReason = ({ reason }) => {
    return (
        <div className="signup-reason">
            <div className="signup-reason-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Solid/Interface/Check">
                        <path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M18.0303 7.96967C18.3232 8.26256 18.3232 8.73744 18.0303 9.03033L11.0303 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="#1675FF"/>
                    </g>
                </svg>
            </div>
            <div className="signup-reason-text">{reason}</div>
        </div>
    )
};

const UserBalanceOrderDivider = () => {
    return (
        <div className="user-balance-order-divider">
            <svg width="2" height="116" viewBox="0 0 2 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.25" d="M1 0L1.00001 116" stroke="#1675FF" />
            </svg>
        </div>
    )
};

const UserBalanceAvailable = ({ user }) => {
    return (
        <div className="user-balance-total">
            <div className="user-balance-total-title">
                <div className="user-balance-total-title-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/bulk/wallet-2">
                            <g id="wallet-2">
                                <path id="Vector" d="M25.6666 12.7982V15.2016C25.6666 15.8433 25.1533 16.3682 24.4999 16.3916H22.2132C20.9532 16.3916 19.7983 15.4699 19.6933 14.2099C19.6233 13.4749 19.9032 12.7866 20.3932 12.3082C20.8249 11.8649 21.4199 11.6083 22.0732 11.6083H24.4999C25.1533 11.6316 25.6666 12.1566 25.6666 12.7982Z" fill="white" />
                                <path id="Vector_2" opacity="0.4" d="M20.3932 12.3084C19.9032 12.7867 19.6233 13.475 19.6933 14.21C19.7983 15.47 20.9532 16.3917 22.2132 16.3917H24.4999V18.0834C24.4999 21.5834 22.1666 23.9167 18.6666 23.9167H8.16658C4.66659 23.9167 2.33325 21.5834 2.33325 18.0834V9.91671C2.33325 6.74337 4.24659 4.5267 7.22159 4.15337C7.52492 4.1067 7.83992 4.08337 8.16658 4.08337H18.6666C18.9699 4.08337 19.2616 4.09503 19.5416 4.14169C22.5516 4.49169 24.4999 6.72004 24.4999 9.91671V11.6084H22.0732C21.4199 11.6084 20.8249 11.865 20.3932 12.3084Z" fill="white" />
                                <g id="Group">
                                    <path id="Vector_3" d="M15.1667 11.375H8.16675C7.68841 11.375 7.29175 10.9783 7.29175 10.5C7.29175 10.0217 7.68841 9.625 8.16675 9.625H15.1667C15.6451 9.625 16.0417 10.0217 16.0417 10.5C16.0417 10.9783 15.6451 11.375 15.1667 11.375Z" fill="white" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="user-balance-total-title-text">Total Balance</div>
            </div>
            <div className="user-balance-total-content">
                <div className="user-balance-total-icon">$</div>
                <div className="user-balance-total-value">{user.balance.toFixed(2)}</div>
            </div>
        </div>
    )
};

const UserBalanceOrder = ({ payment_methods, setError }) => {
    const [topupAmount, setTopupAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loaded, setLoaded] = useState(true);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/checkout/balance`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    amount: topupAmount,
                    payment_method: paymentMethod
                }),
                credentials: "include"
            }).then(r => r.json());
            console.log(data);
            if(!data.success) {
                setLoaded(true);
                setError(data.response || data.message);
                return;
            };

            navigate(data.checkout_url);
        } catch(e) {
            setLoaded(true);
            console.log(e);
            setError("An unknown error occurred while creating your checkout session, please try again later or contact support if this error persists");
        }
    };

    return (
        <div className="user-balance-order">
            <div className="user-balance-order-amount">
                <div className="user-balance-order-title">Top-Up Amount</div>
                <div className="auth-input user-balance-order-input">
                    <div className="auth-input-icon" style={{ width: "54px" }}>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Union" d="M9.49371 1.53943C9.49371 0.944333 9.01129 0.461914 8.4162 0.461914C7.8211 0.461914 7.33869 0.944333 7.33869 1.53943V7.64533H1.23279C0.637692 7.64533 0.155273 8.12774 0.155273 8.72284C0.155273 9.31793 0.637692 9.80035 1.23279 9.80035H7.33869V15.9062C7.33869 16.5013 7.8211 16.9838 8.4162 16.9838C9.01129 16.9838 9.49371 16.5013 9.49371 15.9062V9.80035H15.5996C16.1947 9.80035 16.6771 9.31793 16.6771 8.72284C16.6771 8.12774 16.1947 7.64533 15.5996 7.64533H9.49371V1.53943Z" fill="#1675FF" />
                        </svg>
                    </div>
                    <input className="auth-input-field" placeholder="$0.00" type="number" min={0} max={999} onChange={(e) => setTopupAmount(e.target.value)} />
                </div>
            </div>
            <UserBalanceOrderDivider />
            <div className="user-balance-order-payment">
                <div className="user-balance-order-title">Payment Method</div>
                <div className="user-balance-order-payment-methods">
                    {payment_methods.map(payment_method => {
                        return (
                            <div key={`user-balance-order-payment-method-${payment_method.id}`} className={`user-balance-order-payment-method ${paymentMethod === payment_method.id ? "user-balance-order-payment-method-selected" : null}`} onClick={() => setPaymentMethod(payment_method.id)}>
                                <div className="user-balance-order-payment-method-txt">{payment_method.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <UserBalanceOrderDivider />
            <div className="user-balance-order-checkout">
                <div className="user-balance-order-title">Add <b>${isNaN(parseFloat(topupAmount)) ? (0).toFixed(2) : parseFloat(topupAmount).toFixed(2)}</b></div>
                <button className="btn btn-nav user-balance-order-btn" disabled={!(!isNaN(parseFloat(topupAmount)) && paymentMethod !== null && topupAmount > 0 && loaded)} onClick={() => submit()}>
                    Add Balance
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4697 8.53033C13.1768 8.23744 13.1768 7.76256 13.4697 7.46967C13.7626 7.17678 14.2374 7.17678 14.5303 7.46967L18.5303 11.4697C18.8232 11.7626 18.8232 12.2374 18.5303 12.5303L14.5303 16.5303C14.2374 16.8232 13.7626 16.8232 13.4697 16.5303C13.1768 16.2374 13.1768 15.7626 13.4697 15.4697L16.1893 12.75H6.5C6.08579 12.75 5.75 12.4142 5.75 12C5.75 11.5858 6.08579 11.25 6.5 11.25H16.1893L13.4697 8.53033Z" fill="white" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

const UserBalance = () => {
    const { user, setUser } = useContext(UserContext);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);


    const naviagte = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await fetch(`${API_URL}/session/me`, {
                    credentials: "include"
                });
                if (userResponse.status === 403) return naviagte("/user/login");
                const userResponseData = await userResponse.json();
                if (!userResponseData.success) {
                    setLoaded(true);
                    setError(data.response || data.message);
                    return;
                };

                const dataResponse = await fetch(`${API_URL}/checkout/paymentmethods`, {
                    credentials: "include"
                });
                if (dataResponse.status === 403) return naviagte("/user/login");
                const data = await dataResponse.json();
                if (!data.success) {
                    setLoaded(true);
                    setError(data.response || data.message);
                    return;
                };

                setPaymentMethods(data.payment_methods);
                setLoaded(true);
            } catch (e) {
                console.log(e);
                setError("An unknown error occurred, please try again later or contact support if the issue persists");
                setLoaded(true);
            };
        };
        fetchData();
    }, []);

    if (error !== false) return <StorefrontError error={error} />;
    if (!loaded) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title">Your Balance</div>
            </div>

            <div className="user-balance-hero">
                <UserBalanceAvailable user={user} />
                <UserBalanceOrder payment_methods={paymentMethods} setError={setError} />
            </div>

            <div className="user-balance-reasons">
                <div className="user-balance-reasons-title">Why add balance to your account?</div>
                <div className="user-balance-reasons-subtitle">There are many reasons to add balance to your account if you are a frequent purchaser</div>

                <div className="user-balance-reasons-content">
                    <BalanceReason reason="Use your balance on site-wide puchases without the hassle of going through wallets and confirmations" />
                    <BalanceReason reason="Exclusive Discount Codes for Users with sufficient account balance" />
                    <BalanceReason reason="Combine your leftover balance for your next purchase" />
                    <BalanceReason reason="It's absolutely free of cost, no transaction fees" />
                </div>
            </div>
        </div>
    )
};

export default UserBalance;