import { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Turnstile } from "@marsidev/react-turnstile";
import { Link } from "react-router-dom";
import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY } from "../../../config";
import UserContext from "../../../contexts/user";

const SignupReason = ({ reason }) => {
    return (
        <div className="signup-reason">
            <div className="signup-reason-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Solid/Interface/Check">
                        <path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M18.0303 7.96967C18.3232 8.26256 18.3232 8.73744 18.0303 9.03033L11.0303 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="#1675FF"/>
                    </g>
                </svg>
            </div>
            <div className="signup-reason-text">{reason}</div>
        </div>
    )
};

const Register = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(false);
    const { setUser } = useContext(UserContext);
    const captchaRef = useRef();
    const navigate = useNavigate();

    const getReferral = () => {
        try {
            if(!window.location.href.includes("#")) return null;
            const rf = window.location.href.split("#")[1];
            if(!(rf.length > 2)) return null;
            return rf;
        } catch(e) {
            return null;
        }
    };

    const submit = async() => {
        try {
            const data = await fetch(`${API_URL}/auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username,
                    email: email.toLowerCase(),
                    password,
                    referral: getReferral(),
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCaptcha(null);
                setError(data.response || data.message);
                captchaRef.current?.reset();
                return;
            };

            setUser({
                loggedin: true,
                username: data.user.username,
                email: data.user.email,
                balance: data.user.balance
            });
            Cookies.set("loggedin", "true");
            Cookies.set("username", data.user.username);
            Cookies.set("email", data.user.email);
            Cookies.set("balance", data.user.balance);
            navigate("/user/home");
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    return (
        <div className="signup-page">
            <div className="signup-form">
                <div className="auth-form">
                    <div className="auth-form-title">Get Started</div>
                    <div className="auth-form-subtitle">Create your free account</div>
                    
                    {error !== false ? <div className="auth-form-error">{error}</div> : null}

                    <div className="auth-form-inputs">
                        <div className="auth-input">
                            <div className="auth-input-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/bulk/user-square">
                                        <g id="user-square">
                                            <path id="Vector" opacity="0.4" d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z" fill="white"/>
                                            <path id="Vector_2" d="M18.4401 21.66C17.7801 21.89 17.0201 22 16.1901 22H7.81006C6.98006 22 6.22006 21.89 5.56006 21.66C5.91006 19.02 8.67006 16.97 12.0001 16.97C15.3301 16.97 18.0901 19.02 18.4401 21.66Z" fill="white"/>
                                            <path id="Vector_3" d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z" fill="white"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <input className="auth-input-field" placeholder="Username" type="text" onChange={(e) => setUsername(e.target.value)} />
                        </div>

                        <div className="auth-input">
                            <div className="auth-input-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/bulk/sms">
                                        <g id="sms">
                                            <path id="Vector" opacity="0.4" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" fill="white"/>
                                        <g id="Group">
                                            <path id="Vector_2" d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill="white"/>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <input className="auth-input-field" placeholder="Email" type="text" onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="auth-input">
                            <div className="auth-input-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/bulk/lock">
                                        <g id="lock">
                                            <path id="Vector" d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z" fill="white"/>
                                            <path id="Vector_2" opacity="0.4" d="M22 15V17C22 21 21 22 17 22H7C3 22 2 21 2 17V15C2 11.66 2.7 10.41 5.25 10.1C5.69 10.04 6.19 10.01 6.75 10H17.25C17.81 10.01 18.31 10.04 18.75 10.1C21.3 10.41 22 11.66 22 15Z" fill="white"/>
                                        <g id="Group">
                                            <path id="Vector_3" d="M8 17.0001C7.87 17.0001 7.74 16.9701 7.62 16.9201C7.49 16.8701 7.39001 16.8001 7.29001 16.7101C7.11001 16.5201 7 16.2601 7 16.0001C7 15.8701 7.02999 15.7401 7.07999 15.6201C7.12999 15.5001 7.20001 15.3901 7.29001 15.2901C7.39001 15.2001 7.49 15.1301 7.62 15.0801C7.99 14.9201 8.42999 15.0101 8.70999 15.2901C8.79999 15.3901 8.87001 15.5001 8.92001 15.6201C8.97001 15.7401 9 15.8701 9 16.0001C9 16.2601 8.88999 16.5201 8.70999 16.7101C8.51999 16.8901 8.26 17.0001 8 17.0001Z" fill="white"/>
                                        </g>
                                        <g id="Group_2">
                                            <path id="Vector_4" d="M12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17Z" fill="white"/>
                                        </g>
                                        <g id="Group_3">
                                            <path id="Vector_5" d="M16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71C16.52 16.89 16.26 17 16 17Z" fill="white"/>
                                        </g>
                                    </g>
                                    </g>
                                </svg>
                            </div>
                            <input className="auth-input-field" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="auth-input">
                            <div className="auth-input-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/bulk/lock">
                                        <g id="lock">
                                            <path id="Vector" d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z" fill="white"/>
                                            <path id="Vector_2" opacity="0.4" d="M22 15V17C22 21 21 22 17 22H7C3 22 2 21 2 17V15C2 11.66 2.7 10.41 5.25 10.1C5.69 10.04 6.19 10.01 6.75 10H17.25C17.81 10.01 18.31 10.04 18.75 10.1C21.3 10.41 22 11.66 22 15Z" fill="white"/>
                                        <g id="Group">
                                            <path id="Vector_3" d="M8 17.0001C7.87 17.0001 7.74 16.9701 7.62 16.9201C7.49 16.8701 7.39001 16.8001 7.29001 16.7101C7.11001 16.5201 7 16.2601 7 16.0001C7 15.8701 7.02999 15.7401 7.07999 15.6201C7.12999 15.5001 7.20001 15.3901 7.29001 15.2901C7.39001 15.2001 7.49 15.1301 7.62 15.0801C7.99 14.9201 8.42999 15.0101 8.70999 15.2901C8.79999 15.3901 8.87001 15.5001 8.92001 15.6201C8.97001 15.7401 9 15.8701 9 16.0001C9 16.2601 8.88999 16.5201 8.70999 16.7101C8.51999 16.8901 8.26 17.0001 8 17.0001Z" fill="white"/>
                                        </g>
                                        <g id="Group_2">
                                            <path id="Vector_4" d="M12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17Z" fill="white"/>
                                        </g>
                                        <g id="Group_3">
                                            <path id="Vector_5" d="M16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71C16.52 16.89 16.26 17 16 17Z" fill="white"/>
                                        </g>
                                    </g>
                                    </g>
                                </svg>
                            </div>
                            <input className="auth-input-field" placeholder="Confirm Password" type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                    </div>

                    <div className="auth-form-captcha">
                        <Turnstile siteKey={CLOUDFLARE_TURNSTILE_SITEKEY} ref={captchaRef} onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} />
                    </div>

                    <button className="btn auth-form-btn" disabled={captcha === null} onClick={() => submit()}>Create Account</button>

                    <div className="auth-form-termstext">By Signing up, you agree to our<br /><Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link></div>
                </div>
                <div className="auth-form-blw">
                    <div className="auth-form-sub"><span>Already have an account?</span> <Link to="/user/login">Login</Link></div>
                </div>
            </div>
            <div className="signup-content">
                <div className="signup-content-title">
                    <div className="auth-form-title">Why Sign Up?</div>
                    <div className="auth-form-subtitle">Here's a list of reason why you should create your free account on Fluid Store</div>
                </div>
                
                <div className="signup-reasons">
                    <SignupReason reason="Enjoy 50% faster checkouts, with all your delivery details saved" />
                    <SignupReason reason="Use Store credit/balance on your account for faster checkouts" />
                    <SignupReason reason="All your purchases are saved on your account, for easy access" />
                    <SignupReason reason="Easily contact with customer support by just sharing your username" />
                    <SignupReason reason="Registered users get discounts and notifications about newest drops" />
                    <SignupReason reason="Get access to user exclusive Drops every month" />
                </div>

                <div className="signup-offer">
                    <div className="signup-offer-header">
                        <div className="signup-offer-header-text">Limited Time Offer</div>
                        <div className="signup-offer-header-textsm">*Exclusive Launch offer only for Registered Users</div>
                    </div>
                    <div className="signup-offer-content">
                        New Users Get Exclusive 5% Discount on your first purchase on Fluid Store
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Register;