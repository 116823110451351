import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";
import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY, STORE_NAME } from "../../../config";  
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Turnstile } from "@marsidev/react-turnstile";

const TicketQuestion = ({ index, title, description, inputType, inputPlaceholder, selectOptions, value, setValue }) => {
    return (
        <div className="user-tickets-question">
            <div className="user-tickets-question-index">{index}</div>
            <div className="user-tickets-question-content">
                <div className="user-tickets-question-title">{title}</div>
                <div className="user-tickets-question-description">{description}</div>
                {inputType === "text"
                ?   <textarea rows={6} className="user-tickets-question-input" placeholder={inputPlaceholder} defaultValue={value} onChange={(e) => setValue(e.target.value)} />
                :   <select className="user-tickets-question-input user-tickets-question-input-select" defaultValue={value} onChange={(e) => setValue(e.target.value)}>
                        {selectOptions.concat([{ value: "null", title: "Please select an option" }]).reverse().map((option, index) => {
                            return <option key={`user-tickets-dropdown-value-${index}-${Math.random.toString()}`} value={option.value}>{option.title}</option>;
                        })}
                    </select>
                }
            </div>
        </div>
    )
};

const TicketDescriptionOrder = ({ reason, ticketDescription, setTicketDescription }) => {
    if(reason === "account") {
        return (
            <TicketQuestion
                index={2}
                title="What issue are you having with your account?"
                description="Please describe the issue your having in as much detail as possible"
                inputType="text"
                value={ticketDescription}
                setValue={setTicketDescription}
            />
        );
    } else if(reason === "account_balance") {
        return (
            <TicketQuestion
                index={2}
                title="What issue are you having with your account balance?"
                description="Please describe the issue your having in as much detail as possible"
                inputType="text"
                value={ticketDescription}
                setValue={setTicketDescription}
            />
        );
    } else if(reason === "order") {
        return (
            <TicketQuestion
                index={3}
                title="What issue are you having with your order?"
                description="Please describe the issue your having in as much detail as possible"
                inputType="text"
                value={ticketDescription}
                setValue={setTicketDescription}
            />
        );
    } else if(reason === "other") {
        return (
            <TicketQuestion
                index={2}
                title="What issue are you having?"
                description="Please describe the issue your having in as much detail as possible"
                inputType="text"
                value={ticketDescription}
                setValue={setTicketDescription}
            />
        );
    };
};

const TicketSubmit = ({ index, captchaRef, captcha, setCaptcha, submitTicket }) => {
    return (
        <div className="user-tickets-question">
            <div className="user-tickets-question-index">{index}</div>
            <div className="user-tickets-question-content">
                <div className="user-tickets-question-title">Submit Ticket</div>
                <div className="user-tickets-question-description">Please check that the details above you enetered are correct before submitting your ticket</div>
                <div className="user-tickets-question-captcha">
                    <Turnstile
                        siteKey={CLOUDFLARE_TURNSTILE_SITEKEY}
                        ref={captchaRef}
                        onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} 
                    />
                </div>
                <button 
                    className="btn btn-nav ticket-submit-btn"
                    disabled={captcha === null}
                    onClick={() => submitTicket()}
                >Submit Ticket</button>
            </div>
        </div>
    );
};

const NewTicket = () => {
    const [orders, setOrders] = useState([]);

    const [ticketReason, setTicketReason] = useState("null");
    const [ticketOrderId, setTicketOrderId] = useState("null");
    const [ticketDescription, setTicketDescription] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(null);
    const captchaRef = useRef();
    const navigate = useNavigate();

    const configureFromURL = () => {
        try {
            if(!window.location.href.includes("#")) return;

            let data = window.location.href.split("#")[1];
            if(!data.includes(".")) return;
            if(data.split(".")[1].length !== 24) return;

            let type = data.split(".")[0];
            let value = data.split(".")[1];

            if(!["order"].includes(type)) return;
            
            if(type === "order") {
                setTicketReason("order");
            };

            setTicketOrderId(value);
        } catch(e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const fetchData = async() => {
            try {
                const orders_data = await fetch(`${API_URL}/user/dashboard/orders`, {
                    method: "GET",
                    credentials: "include"
                }).then(r => r.json());
                if(!orders_data.success) {
                    setError(orders_data.response);
                    setLoaded(orders_data);
                    return;
                };
                setOrders(orders_data.orders);
                setLoaded(true);
                configureFromURL();
            } catch(e) {
                console.log(e);
                setError("An unknown error occurred, please try again later!");
            }
        };
        fetchData();
    }, []);

    const submitTicket = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/user/dashboard/tickets/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ticket_reason: ticketReason,
                    ticket_order_id: ticketOrderId.toString(),
                    ticket_description: ticketDescription,
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            navigate(`/user/tickets/${data.ticket_id}`);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-home-welcome user-tickets-welcome">
                <div className="user-home-welcome">
                    <div className="user-home-welcome-title">New Ticket</div>
                </div>
                <button className="btn btn-snb" onClick={() => navigate("/user/tickets")}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
            </div>

            <div className="user-ticket-new">
                <TicketQuestion
                    index={1}
                    title="What do you need help with?"
                    description={'Select the option that relates most to your query, if none of them relate to your query select "something else"'}
                    inputType="select"
                    selectOptions={
                        [
                            {
                                value: "other",
                                title: "Something else"
                            },
                            {
                                value: "account",
                                title: `A problem with my ${STORE_NAME} account`
                            },
                            {
                                value: "order",
                                title: "A problem with an order"
                            }
                        ]
                    }
                    value={ticketReason}
                    setValue={setTicketReason}
                />
                {ticketReason === "order"
                ?   <TicketQuestion
                        index={2}
                        title="Which order do you need help with?"
                        description={'Select the order which relates to your query, if your looking for help with a guest order select "guest order"'}
                        inputType="select"
                        selectOptions={[{ value: "guest", title: "A guest order (Enter Order ID below)" }].concat(orders.map(order => {
                            return { value: order.order_id, title: `${order.title} - ${order.status.at(0).toUpperCase() + order.status.replaceAll("_", " ").slice(1)} (${new Date(order.created_at).toLocaleString()})` };
                        }))}
                        value={ticketOrderId}
                        setValue={setTicketOrderId}
                    />
                : null}

                {ticketReason !== "null" && (ticketReason === "order" ? ticketOrderId !== "null" : true)
                ? <TicketDescriptionOrder reason={ticketReason} ticketDescription={ticketDescription} setTicketDescription={setTicketDescription} />
                : null}

                {(ticketReason !== "null" && (ticketReason === "order" ? ticketOrderId !== "null" : true)) && ticketDescription !== null
                ? <TicketSubmit index={ticketReason === "order" ? 4 : 3} captchaRef={captchaRef} captcha={captcha} setCaptcha={setCaptcha} submitTicket={() => submitTicket()} />
                : null}
            </div>
        </div>
    );
};

export default NewTicket;