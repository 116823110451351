import { Link } from "react-router-dom";

import { TELEGRAM_CHANNEL_URL, STORE_NAME } from "../../../config";

const Footer = () => {
    return (
        <div className="footer">
            <div className="storefront">
                <div className="footer-content">
                    <div className="footer-nav">
                        <Link className="btn btn-snb btn-nav btn-nav-secondary" to="/terms">Terms Of Service</Link>
                        <Link className="btn btn-snb btn-nav btn-nav-secondary" to="/privacy">Privacy Policy</Link>
                        <Link className="btn btn-snb btn-nav btn-nav-secondary" to="/faq">FAQs</Link>
                    </div>
                    <div className="footer-branding-img">
                        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.489324" y="0.489324" width="54.0214" height="54.0214" rx="9.29715" fill="#1675FF" stroke="#002E94" stroke-width="0.978648"/>
                            <path d="M14.6955 19.6329L9.854 13.2889C9.285 12.5433 9.81667 11.4688 10.7546 11.4688H46.7762C47.7961 11.4688 48.2966 12.7109 47.5617 13.418L40.9672 19.7621C40.7561 19.9651 40.4747 20.0785 40.1818 20.0785H15.5961C15.2428 20.0785 14.9098 19.9137 14.6955 19.6329Z" fill="white"/>
                            <path d="M14.191 27.2448L15.8823 23.0532C16.0552 22.6248 16.4709 22.3442 16.9329 22.3442H37.252C38.273 22.3442 38.773 23.5887 38.0358 24.2951L18.2903 43.2143C17.3553 44.1103 15.8914 42.9895 16.5125 41.8531L22.7293 30.4777C23.1419 29.7228 22.5955 28.8015 21.7352 28.8015H15.2416C14.4386 28.8015 13.8905 27.9894 14.191 27.2448Z" fill="white"/>
                        </svg>
                    </div>
                    <div className="footer-nav nav-right">
                        <Link className="btn btn-snb btn-nav btn-nav-secondary" to="/">Follow Us</Link>
                        <Link className="btn btn-snb btn-nav btn-nav-secondary btn-nav-np" to="/faq">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4831 19.79L18.4838 19.7883L18.5011 19.7448L21.4999 4.62568V4.57657C21.4999 4.19987 21.3608 3.87101 21.0579 3.67375C20.7928 3.50109 20.488 3.48879 20.2743 3.50501C20.0483 3.52217 19.8352 3.57858 19.6876 3.62554C19.6117 3.6497 19.5482 3.67298 19.5028 3.69059C19.48 3.69943 19.4615 3.70692 19.448 3.71251L19.4348 3.71807L2.71502 10.277L2.71042 10.2787C2.70139 10.2819 2.68944 10.2864 2.67502 10.292C2.64627 10.3032 2.60713 10.3192 2.56115 10.3401C2.47087 10.381 2.34613 10.4441 2.21963 10.5307C2.00477 10.6779 1.59566 11.0262 1.66474 11.5784C1.72196 12.0358 2.03727 12.3263 2.24983 12.4767C2.36378 12.5574 2.4728 12.6155 2.55262 12.6535C2.59307 12.6728 2.62743 12.6875 2.65302 12.6979C2.66585 12.7032 2.67657 12.7073 2.68487 12.7105L2.6955 12.7144L2.70229 12.717L5.62753 13.7018C5.61766 13.8853 5.63586 14.0724 5.68453 14.2571L7.14982 19.8155C7.32355 20.4745 7.91978 20.9336 8.60135 20.9331C9.21228 20.9327 9.75411 20.5631 9.98429 20.0117L12.272 17.5656L16.2009 20.5778L16.2568 20.6022C16.6138 20.758 16.9473 20.8073 17.2522 20.7657C17.5567 20.7241 17.7987 20.5964 17.9804 20.451C18.1593 20.3079 18.2819 20.1464 18.359 20.0248C18.3981 19.963 18.4273 19.9087 18.4476 19.8679C18.4578 19.8473 18.4658 19.83 18.4718 19.8164L18.4793 19.799L18.482 19.7926L18.4831 19.79ZM7.13493 13.8747C7.10163 13.7484 7.15391 13.615 7.2642 13.545L17.1862 7.24407C17.1862 7.24407 17.7698 6.88978 17.7489 7.24407C17.7489 7.24407 17.8531 7.30664 17.5404 7.59837C17.2438 7.87544 10.4687 14.4165 9.78316 15.0784C9.74359 15.1166 9.71997 15.1604 9.70602 15.2136L8.60022 19.4331L7.13493 13.8747Z" fill="white"/>
                            </svg>
                        </Link>
                        <Link className="btn btn-snb btn-nav btn-nav-secondary btn-nav-np" to="/faq">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 6C20 6 17.707 4.205 15 4L14.756 4.4885C17.203 5.088 18.326 5.945 19.5 7C17.4765 5.967 15.48 5 12 5C8.52 5 6.5235 5.967 4.5 7C5.674 5.945 7.01 4.992 9.244 4.4885L9 4C6.16 4.2675 4 6 4 6C4 6 1.4395 9.713 1 17C3.58 19.9765 7.5 20 7.5 20L8.32 18.908C6.928 18.424 5.358 17.5605 4 16C5.619 17.225 8.0625 18.5 12 18.5C15.9375 18.5 18.381 17.225 20 16C18.6425 17.5605 17.0725 18.424 15.68 18.908L16.5 20C16.5 20 20.42 19.9765 23 17C22.5605 9.713 20 6 20 6ZM8.75 15C7.783 15 7 14.105 7 13C7 11.895 7.783 11 8.75 11C9.717 11 10.5 11.895 10.5 13C10.5 14.105 9.717 15 8.75 15ZM15.25 15C14.283 15 13.5 14.105 13.5 13C13.5 11.895 14.283 11 15.25 11C16.217 11 17 11.895 17 13C17 14.105 16.217 15 15.25 15Z" fill="white"/>
                            </svg>
                        </Link>
                        <Link className="btn btn-snb btn-nav btn-nav-secondary btn-nav-np" to="/faq">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.8098 6.22724C21.8677 6.14647 21.7821 6.04218 21.6894 6.07811C21.0307 6.33344 20.3423 6.50571 19.64 6.5906C20.4219 6.12348 21.0253 5.4124 21.3599 4.57053C21.394 4.48477 21.3003 4.40819 21.2195 4.4528C20.4928 4.85414 19.7091 5.14313 18.8949 5.30957C18.8608 5.31654 18.8257 5.30494 18.8018 5.27969C18.1908 4.63502 17.3866 4.20578 16.5098 4.05745C15.6147 3.90601 14.6946 4.05596 13.8938 4.48379C13.0931 4.91162 12.457 5.59313 12.0853 6.42144C11.7319 7.209 11.6365 8.08691 11.8108 8.93003C11.8243 8.99545 11.7731 9.05721 11.7065 9.05307C10.1224 8.95469 8.57469 8.53336 7.15868 7.81446C5.74627 7.09739 4.49554 6.09983 3.48267 4.8833C3.43772 4.82931 3.35252 4.83629 3.31977 4.89845C3.0046 5.49651 2.83958 6.16311 2.84003 6.8406C2.83875 7.51498 3.00425 8.17922 3.32178 8.77416C3.63932 9.36911 4.09905 9.87631 4.66003 10.2506C4.0651 10.2344 3.48178 10.0873 2.95128 9.82067C2.88291 9.78631 2.8009 9.83524 2.80446 9.91167C2.84583 10.8009 3.17228 11.6936 3.73734 12.3786C4.33866 13.1074 5.17328 13.6062 6.10003 13.7906C5.74328 13.8992 5.3729 13.9564 5.00003 13.9606C4.79709 13.9582 4.59454 13.9433 4.39356 13.9158C4.31904 13.9056 4.2582 13.9764 4.28428 14.0469C4.5607 14.7944 5.04903 15.4467 5.6911 15.9228C6.37755 16.4318 7.2056 16.7142 8.06003 16.7306C6.61723 17.8659 4.83591 18.4855 3.00003 18.4906C2.81138 18.4912 2.62282 18.4851 2.43471 18.4723C2.3287 18.4651 2.27995 18.6063 2.37132 18.6605C4.16009 19.7221 6.20462 20.2831 8.29003 20.2806C9.82972 20.2966 11.3572 20.0056 12.7831 19.4247C14.2091 18.8437 15.5051 17.9845 16.5952 16.8971C17.6854 15.8097 18.548 14.516 19.1326 13.0915C19.7172 11.667 20.0121 10.1403 20 8.6006V8.12077C20 8.0892 20.015 8.05951 20.0402 8.04048C20.7184 7.52834 21.3149 6.91691 21.8098 6.22724Z" fill="white"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className="footer-branding-txt">&copy; {new Date().getFullYear()} {STORE_NAME} Store. All rights reserved</div>
            </div>
        </div>
    )
};

export default Footer;