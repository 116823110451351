import { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import CartContext from "../../../contexts/cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../../config";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: "0"
    },
};

const Item = ({ category_id, name, description, image, tags, products }) => {
    const [productModalOpen, setProductModalOpenState] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setProductQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { cart, setCart } = useContext(CartContext);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const setQuantity = (newQuantity) => {
        if(newQuantity >= 5000) return;
        setProductQuantity(newQuantity);
    };

    const closeModal = () => {
        setProductModalOpen(false);
    };

    const setProductModalOpen = (value) => {
        if(value === true) {
            document.body.setAttribute("style", "overflow: hidden;");
        } else {
            document.body.setAttribute("style", "");
        };
        setProductModalOpenState(value);
    };

    const getCheapestPrice = (products) => {
        if(!(products.length > 0)) return 0;
        return products.reduce((prev, curr) => prev.price < curr.price ? prev : curr).price;
    };

    const addToCart = async() => {
        if(quantity < product.min_quantity) setProductQuantity(product.min_quantity);

        setLoading(true);
        setError(false);
        try { 
            const data = await fetch(`${API_URL}/cart/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    item_id: product.product_id,
                    quantity: parseInt(quantity)
                }),
                credentials: "include"
            }).then(r => r.json());
            setLoading(false);
            if(!data.success) return setError(data.response || data.message);

            if(cart.findIndex(p => p.product_id === product.product_id) === -1) {
                setCart([...cart, {
                    product_id: product.product_id,
                    quantity,
                    price_per_item: product.price
                }]);
            } else {
                setCart(cart.map(p => {
                    if(p.product_id === product.product_id) {
                        p.quantity += quantity;
                        return p;
                    } else return p;
                }));
            };

            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
                setProduct(products[0]);
                setQuantity(products[0].min_quantity);
                setProductModalOpen(false);
            }, 1500);
        } catch(e) {
            setError("An unknown error occurred, please try again later or contact support if the issues persists");
        }
    };

    useEffect(() => {
        products = products.sort((a, b) => a.price - b.price);
        setProduct(products[0]);
        setQuantity(products[0].min_quantity);
    }, []);

    useEffect(() => {
        setProductQuantity(product.min_quantity);
    }, [product]);

    return (
        <>
            <div className="storefront-item">
                <img className="storefront-item-image" src={image} />
                <div className="storefront-item-content">
                    <div className="storefront-item-name">{name}</div>
                    <button className="btn storefront-item-button" onClick={() => setProductModalOpen(true)}>
                        <div className="storefront-btn-item-txt">Buy Now</div>
                        <div className="storefront-btn-item-price">${products[0].price.toFixed(2)}</div>
                    </button>
                </div>
            </div>
            <Modal
                isOpen={productModalOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                id={`product-modal-${category_id}`}
            >
                <div className="storefront-modal-title">
                    <div>{name}</div>
                    <div className="dflx-center hover-pointer" onClick={() => setProductModalOpen(false)}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.0822 11.244C20.4483 10.8779 20.4483 10.2843 20.0822 9.91814C19.7161 9.55203 19.1225 9.55203 18.7564 9.91814L14.9999 13.6746L11.2434 9.91814C10.8773 9.55203 10.2837 9.55203 9.91754 9.91814C9.55143 10.2843 9.55143 10.8779 9.91754 11.244L13.674 15.0005L9.91753 18.757C9.55142 19.1231 9.55142 19.7167 9.91753 20.0828C10.2836 20.4489 10.8772 20.4489 11.2434 20.0828L14.9999 16.3263L18.7564 20.0828C19.1225 20.4489 19.7161 20.4489 20.0822 20.0828C20.4483 19.7167 20.4483 19.1231 20.0822 18.757L16.3257 15.0005L20.0822 11.244Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="storefront-modal-content">
                    {error !== false ? <div className="auth-form-error mt-2">{error}</div> : null}
                    <div className="storefront-modal-value-title">Description</div>
                    <div className="storefront-modal-description">{description.split("\n").map((s, index) => <div key={`storefront-modal-desc-line-${category_id}-${index}`} className="text-line">{s}</div>)}</div>

                    {tags.length > 0 ? <div className="storefront-product-tags">
                        <div className="storefront-product-tags-title">Category</div>
                        <div className="storefront-product-tags-container">
                            {tags.map((tag, index) => (
                                <div key={`storefront-item-tag-${category_id}-${index}`} className="storefront-product-tag">{tag.name}</div>
                            ))}
                        </div>
                    </div> : null}

                    <div className="storefront-modal-options">
                        <div className="input-group">
                            <label>Select Variant</label>
                            <select className="storefront-modal-input" defaultValue={product.product_id} onChange={(e) => {
                                setProduct(products[products.findIndex(p => p.product_id === e.target.value)]);
                            }}>
                                {products.map((product_obj, index) => {
                                    return <option key={`storefront-item-value-${product_obj.product_id}`} value={product_obj.product_id}>{product_obj.name} (${product_obj.price.toFixed(2)} per item) - {product_obj.stock} in stock</option>;
                                })}
                            </select>
                        </div>

                        <div className="input-group">
                            <div className="storefront-quantity-text">Quantity</div>
                            <input className="storefront-modal-input" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} min={product.min_quantity} max={5000} />
                        </div>
                    </div>
                </div>
                <div className="storefront-modal-footer">
                    <div className="storefront-modal-total">
                        <div className="storefront-modal-txt">Total</div>
                        <div className="storefront-modal-price">${(product.price * quantity).toFixed(2)}</div>
                    </div>
                    <div className="storefront-modal-btns">
                        <button className="btn storefront-modal-btn smb-lg disabled-alwayswhite" disabled={showSuccess || loading} onClick={() => addToCart()}>
                            {showSuccess
                                ? <><FontAwesomeIcon icon={faCircleCheck} /> Success</>
                                : <>Buy Now</>
                            }
                        </button>
                        <button className="btn storefront-modal-btn smb-sm btn-nav-cart" onClick={() => navigate("/cart")}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="vuesax/bulk/shopping-cart">
                                    <g id="shopping-cart">
                                        <path id="Vector" d="M14.8959 20.6251C15.7819 20.6251 16.5001 19.9069 16.5001 19.0209C16.5001 18.135 15.7819 17.4167 14.8959 17.4167C14.01 17.4167 13.2917 18.135 13.2917 19.0209C13.2917 19.9069 14.01 20.6251 14.8959 20.6251Z" fill="white"/>
                                        <path id="Vector_2" d="M7.56242 20.6251C8.44838 20.6251 9.16659 19.9069 9.16659 19.0209C9.16659 18.135 8.44838 17.4167 7.56242 17.4167C6.67646 17.4167 5.95825 18.135 5.95825 19.0209C5.95825 19.9069 6.67646 20.6251 7.56242 20.6251Z" fill="white"/>
                                        <path id="Vector_3" opacity="0.4" d="M4.43658 3.61159L4.25325 5.85742C4.21658 6.28825 4.55575 6.64575 4.98659 6.64575H19.0208C19.4058 6.64575 19.7266 6.35241 19.7541 5.96741C19.8733 4.34491 18.6357 3.02492 17.0132 3.02492H5.76575C5.67408 2.62159 5.49075 2.23658 5.20658 1.91575C4.75741 1.42992 4.11576 1.14575 3.45576 1.14575H1.83325C1.45742 1.14575 1.14575 1.45742 1.14575 1.83325C1.14575 2.20909 1.45742 2.52075 1.83325 2.52075H3.42826C3.71242 2.52075 3.97825 2.63992 4.17075 2.84159C4.36325 3.05242 4.45492 3.32742 4.43658 3.61159Z" fill="white"/>
                                        <path id="Vector_4" d="M18.8007 8.02075H4.73906C4.35406 8.02075 4.04239 8.31408 4.00572 8.68991L3.67572 12.6774C3.54739 14.2358 4.77572 15.5833 6.34322 15.5833H16.5366C17.9116 15.5833 19.1216 14.4558 19.2224 13.0808L19.5249 8.79992C19.5616 8.37826 19.2316 8.02075 18.8007 8.02075Z" fill="white"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default Item;