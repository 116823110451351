import { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import UserContext from "../../../contexts/user";
import { API_URL, STORE_NAME } from "../../../config";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

const copyText = (text) => {
    var input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
};

const SignupReason = ({ reason }) => {
    return (
        <div className="signup-reason">
            <div className="signup-reason-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Solid/Interface/Check">
                        <path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M18.0303 7.96967C18.3232 8.26256 18.3232 8.73744 18.0303 9.03033L11.0303 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="#1675FF" />
                    </g>
                </svg>
            </div>
            <div className="referral-reason-text">{reason}</div>
        </div>
    )
};

const UserReferral = () => {
    const { user, setUser } = useContext(UserContext);
    const [referralToken, setReferralToken] = useState(null);
    const [usersReferred, setUsersReferred] = useState(0);
    const [referralRewards, setReferralRewards] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const naviagte = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataResponse = await fetch(`${API_URL}/user/dashboard/referral`, {
                    credentials: "include"
                });
                if (dataResponse.status === 403) return naviagte("/user/login");
                const data = await dataResponse.json();
                if (!data.success) {
                    setLoaded(true);
                    setError(data.response || data.message);
                    return;
                };

                setReferralToken(data.referral.token);
                setUsersReferred(data.referral.total_users);
                setReferralRewards(data.referral.total_rewards);
                setLoaded(true);
            } catch (e) {
                console.log(e);
                setError("An unknown error occurred, please try again later or contact support if the issue persists");
                setLoaded(true);
            };
        };
        fetchData();
    }, []);

    if (error !== false) return <StorefrontError error={error} />;
    if (!loaded) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title">Referral System</div>
            </div>
            <div className="user-referral-pg">
                <div className="user-referral-link">
                    <div className="user-referral-link-title">Your Referral Link</div>
                    <div className="auth-input">
                        <div className="auth-input-icon hover-pointer" onClick={() => copyText(`https://${window.location.hostname}/user/register#${referralToken}`)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="vuesax/bulk/link">
                                    <g id="link">
                                        <g id="Group">
                                            <path id="Vector" d="M12.5002 14.75H10.0002C9.59024 14.75 9.25024 14.41 9.25024 14C9.25024 13.59 9.59024 13.25 10.0002 13.25H12.5002C15.1202 13.25 17.2502 11.12 17.2502 8.5C17.2502 5.88 15.1202 3.75 12.5002 3.75H7.50024C4.88024 3.75 2.75024 5.88 2.75024 8.5C2.75024 9.6 3.14023 10.67 3.84023 11.52C4.10023 11.84 4.06023 12.31 3.74023 12.58C3.42023 12.84 2.95024 12.8 2.68024 12.48C1.75024 11.36 1.24023 9.95 1.24023 8.5C1.24023 5.05 4.04023 2.25 7.49023 2.25H12.4902C15.9402 2.25 18.7402 5.05 18.7402 8.5C18.7402 11.95 15.9502 14.75 12.5002 14.75Z" fill="white" />
                                        </g>
                                        <g id="Group_2">
                                            <path id="Vector_2" opacity="0.4" d="M16.5 21.75H11.5C8.05 21.75 5.25 18.95 5.25 15.5C5.25 12.05 8.05 9.25 11.5 9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75H11.5C8.88 10.75 6.75 12.88 6.75 15.5C6.75 18.12 8.88 20.25 11.5 20.25H16.5C19.12 20.25 21.25 18.12 21.25 15.5C21.25 14.4 20.86 13.33 20.16 12.48C19.9 12.16 19.94 11.69 20.26 11.42C20.58 11.15 21.05 11.2 21.32 11.52C22.25 12.64 22.76 14.05 22.76 15.5C22.75 18.95 19.95 21.75 16.5 21.75Z" fill="white" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="auth-input-field referral-input-field hover-pointer" disabled={true} placeholder="Referral Link" onClick={() => copyText(`https://${window.location.hostname}/user/register#${referralToken}`)}>https://{window.location.hostname}/user/register#{referralToken}</div>
                    </div>
                </div>
                <div className="user-referral-link user-referral-system">
                    <div className="user-referral-system-title">How does the Referral System work?</div>
                    <div className="user-referral-system-subtitle">Invite new users with your personal link, have them create account and purchase from the store<br />If they purchase <span className="text-theme">$10</span> worth of items you will be credited <span className="text-theme">$5</span> on your account balance</div>

                    <div className="user-referral-system-reasons">
                        <SignupReason reason={<>The users you invite must spend at least <span className="text-theme">$10</span> in shop to receive your referral income</>} />
                        <SignupReason reason={<>To get the most amount of rewards as possible send this link to as many people as you can</>} />
                        <SignupReason reason={<>For example if you invite <span className="text-theme">10</span> different people and they each spend <span className="text-theme">$10</span> on the site, you will get <span className="text-theme">$50</span> balance credited to you</>} />
                        <SignupReason reason={<>If you invite over <span className="text-theme">100+</span> users we will allow balance withdrawals meaning if you can find a platform to advertise to a large audience and make money</>} />
                    </div>
                </div>
                <div className="user-referral-stats">
                    <div className="user-referral-stat">
                        <div className="user-refferal-stat-title">
                            <div className="user-refferal-stat-title-icon">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/bulk/people">
                                        <g id="people">
                                            <path id="Vector" opacity="0.4" d="M20.4517 9.06505C20.37 9.05338 20.2884 9.05338 20.2067 9.06505C18.3984 9.00671 16.9634 7.52504 16.9634 5.70504C16.9634 3.85004 18.4684 2.33337 20.335 2.33337C22.19 2.33337 23.7067 3.83837 23.7067 5.70504C23.695 7.52504 22.26 9.00671 20.4517 9.06505Z" fill="white" />
                                            <path id="Vector_2" opacity="0.4" d="M24.255 17.1498C22.9484 18.0248 21.1167 18.3515 19.425 18.1298C19.8684 17.1732 20.1017 16.1115 20.1134 14.9915C20.1134 13.8249 19.8567 12.7165 19.3667 11.7482C21.0934 11.5149 22.925 11.8415 24.2434 12.7165C26.0867 13.9298 26.0867 15.9248 24.255 17.1498Z" fill="white" />
                                            <path id="Vector_3" opacity="0.4" d="M7.5133 9.06505C7.59496 9.05338 7.67663 9.05338 7.7583 9.06505C9.56663 9.00671 11.0016 7.52504 11.0016 5.70504C11.0016 3.85004 9.49663 2.33337 7.62997 2.33337C5.77497 2.33337 4.2583 3.83837 4.2583 5.70504C4.26997 7.52504 5.70496 9.00671 7.5133 9.06505Z" fill="white" />
                                            <path id="Vector_4" opacity="0.4" d="M7.6418 14.9918C7.6418 16.1234 7.8868 17.1968 8.33014 18.1651C6.68514 18.3401 4.97014 17.9901 3.71014 17.1618C1.8668 15.9368 1.8668 13.9417 3.71014 12.7167C4.95847 11.8767 6.72014 11.5384 8.3768 11.7251C7.89847 12.7051 7.6418 13.8134 7.6418 14.9918Z" fill="white" />
                                            <path id="Vector_5" d="M14.1396 18.515C14.0463 18.5033 13.9413 18.5033 13.8363 18.515C11.6896 18.445 9.97461 16.6833 9.97461 14.5133C9.97461 12.2967 11.7596 10.5 13.9879 10.5C16.2046 10.5 18.0013 12.2967 18.0013 14.5133C18.0013 16.6833 16.2979 18.445 14.1396 18.515Z" fill="white" />
                                            <path id="Vector_6" d="M10.3481 20.9298C8.58644 22.1082 8.58644 24.0448 10.3481 25.2115C12.3548 26.5532 15.6448 26.5532 17.6514 25.2115C19.4131 24.0332 19.4131 22.0965 17.6514 20.9298C15.6564 19.5882 12.3664 19.5882 10.3481 20.9298Z" fill="white" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="user-refferal-stat-title-text">Total Referred Users</div>
                        </div>
                        <div className="user-referral-stat-value">
                            {usersReferred}
                        </div>
                    </div>
                    <div className="user-referral-stat">
                        <div className="user-refferal-stat-title">
                            <div className="user-refferal-stat-title-icon">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/bulk/dollar-square">
                                        <g id="dollar-square">
                                            <path id="Vector" opacity="0.4" d="M18.8885 2.33337H9.11183C4.86516 2.33337 2.3335 4.86504 2.3335 9.11171V18.8767C2.3335 23.135 4.86516 25.6667 9.11183 25.6667H18.8768C23.1235 25.6667 25.6552 23.135 25.6552 18.8884V9.11171C25.6668 4.86504 23.1352 2.33337 18.8885 2.33337Z" fill="white"/>
                                            <path id="Vector_2" d="M16.6368 14L14.8752 13.3817V9.42667H15.2952C16.2402 9.42667 17.0102 10.255 17.0102 11.27C17.0102 11.7483 17.4068 12.145 17.8852 12.145C18.3635 12.145 18.7602 11.7483 18.7602 11.27C18.7602 9.28667 17.2085 7.67667 15.2952 7.67667H14.8752V7C14.8752 6.52167 14.4785 6.125 14.0002 6.125C13.5218 6.125 13.1252 6.52167 13.1252 7V7.67667H12.3668C10.6402 7.67667 9.22852 9.135 9.22852 10.92C9.22852 13.0083 10.4418 13.6733 11.3635 14L13.1252 14.6183V18.5617H12.7052C11.7602 18.5617 10.9902 17.7333 10.9902 16.7183C10.9902 16.24 10.5935 15.8433 10.1152 15.8433C9.63685 15.8433 9.24018 16.24 9.24018 16.7183C9.24018 18.7017 10.7918 20.3117 12.7052 20.3117H13.1252V21C13.1252 21.4783 13.5218 21.875 14.0002 21.875C14.4785 21.875 14.8752 21.4783 14.8752 21V20.3233H15.6335C17.3602 20.3233 18.7718 18.865 18.7718 17.08C18.7602 14.98 17.5469 14.315 16.6368 14ZM11.9468 12.355C11.3518 12.145 10.9902 11.9467 10.9902 10.9317C10.9902 10.1033 11.6085 9.43833 12.3785 9.43833H13.1368V12.775L11.9468 12.355ZM15.6335 18.5733H14.8752V15.2367L16.0535 15.645C16.6485 15.855 17.0102 16.0533 17.0102 17.0683C17.0102 17.8967 16.3918 18.5733 15.6335 18.5733Z" fill="white"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="user-refferal-stat-title-text">Total Referral Income</div>
                        </div>
                        <div className="user-referral-stat-value ursv-b">
                            ${referralRewards.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UserReferral;