import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";

import UserContext from "../../../contexts/user";
import CartContext from "../../../contexts/cart";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faTrashCan, faBasketShopping, faShoppingCart, faDollarSign } from "@fortawesome/free-solid-svg-icons";

import { API_URL, CLOUDFLARE_TURNSTILE_CHECKOUT_SITEKEY } from "../../../config";

const logos = {
    "Cryptocurrency": faCoins,
    "Cashapp": faDollarSign
};

const CartItem = ({ updateCart, product_id, image, parent_name, name, price_per_item, min_quantity, quantity, updateCartPage }) => {
    const [loading, setLoading] = useState(false);
    const setQuantity = async(new_quantity) => {
        setLoading(true);
        if(new_quantity < quantity) {
            const data = await fetch(`${API_URL}/cart/remove`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    item_id: product_id,
                    quantity: quantity - new_quantity
                }),
                credentials: "include"
            }).then(r => r.json());
            setLoading(false);
            if(data.success) {
                updateCartPage(); 
                updateCart();
            }
        } else if(new_quantity > quantity) {
            try {
                const data = await fetch(`${API_URL}/cart/add`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        item_id: product_id,
                        quantity: new_quantity - quantity
                    }),
                    credentials: "include"
                }).then(r => r.json());
                setLoading(false);
                if(data.success) {
                    updateCartPage(); 
                    updateCart();
                }
            } catch(e) {

            };
        };
    };

    const RemoveItem = async() => {
        setLoading(true);
        try {
            const data = await fetch(`${API_URL}/cart/remove`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    item_id: product_id,
                    quantity: quantity
                }),
                credentials: "include"
            }).then(r => r.json());
            setLoading(false);
            if(data.success) {
                updateCartPage();
                updateCart();
            }
        } catch(e) {

        };
    };

    if(loading) return (
        <div className="storefront-cart-item">
            <StorefrontLoading />
        </div>
    );

    return (
        <div className="storefront-cart-item">
            {/* <button className="btn btn-snb storefront-cart-remove-btn" onClick={() => RemoveItem()}><FontAwesomeIcon icon={faTrashCan} /></button> */}

            <div className="storefront-cart-item-content">
                <img className="storefront-cart-image" src={image} alt="Item icon" />
                <div className="storefront-cart-item-contenxt-txt">
                    <div className="storefront-cart-content-title">{parent_name}</div>
                    <div className="storefront-cart-content-subtitle">{name}</div>
                </div>
            </div>
            <div className="storefront-cart-item-options">
                <div className="storefront-cart-item-option">
                    <div className="storefront-cart-item-option-title">Price:</div>
                    <div className="storefront-cart-item-option-value">${price_per_item.toFixed(2)}</div>
                </div>
                <div className="storefront-cart-item-option-divider"></div>
                <div className="storefront-cart-item-option">
                    <div className="storefront-cart-item-option-title">Qty:</div>
                    <div className="storefront-cart-item-option-value ml-8px">
                        <div className="storefront-quantity-control storefront-cart-quality-control">
                            <button className="btn storefront-quantity-btn storefront-cart-quantity-btn" onClick={() => setQuantity(quantity - 1)}>-</button>
                            <input type="text" value={quantity} onChange={(e) => setQuantity(e.target.value)} min={min_quantity} disabled />
                            <button className="btn storefront-quantity-btn storefront-cart-quantity-btn" onClick={() => setQuantity(quantity + 1)}>+</button>
                        </div>
                    </div>
                </div>
                <div className="storefront-cart-item-option-divider"></div>
                <div className="storefront-cart-item-option">
                    <div className="storefront-cart-item-option-title">Total:</div>
                    <div className="storefront-cart-item-option-value">${(price_per_item * quantity).toFixed(2)}</div>
                </div>
            </div>
        </div>
    )
};

const SectionTitle = ({ icon, title }) => {
    return (
        <div className="cart-section-title">
            <div className="cart-section-title-icon">{icon}</div>
            <div className="cart-section-title-text">{title}</div>
        </div>
    )
};

const Cart = ({ updateCart }) => {
    const { user } = useContext(UserContext);
    const { setCart: setCartContext } = useContext(CartContext);
    const guestCheckout = !(user !== null && user.loggedin !== false);

    const [cart, setCart] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [guestEmail, setGuestEmail] = useState(null);
    const [useAccountBalance, setUseAccountBalance] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [couponCode, setCouponCode] = useState(null);
    const [couponLoaded, setCouponLoaded] = useState(true);
    const [couponError, setCouponError] = useState(false);
    const [couponDiscount, setCouponDiscount] = useState(0);

    const navigate = useNavigate();

    const applyCouponCode = async() => {
        setCouponLoaded(false);
        try {
            const data = await fetch(`${API_URL}/checkout/coupon`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    coupon_code: couponCode
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCouponLoaded(true);
                setCouponError(data.response);
                return;
            };

            setCouponDiscount(data.coupon_discount);
            setDiscountAmount(cartTotal * data.coupon_discount);
            setCouponLoaded(true);
        } catch(e) {
            setCouponLoaded(true);
            console.log(e);
            setCouponError("An unknown error occurred, please try again later or contact support if this error persists");
        };
    };

    const updateCartPage = async() => {
        try {
            const data = await fetch(`${API_URL}/cart`, {
                method: "GET",
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setError(data.response || data.message);
                return;
            }
            setCart(data.cart);
            setCartTotal(data.cart_total);
            setCartContext(data.cart.map(c => {
                return {
                    product_id: c.product_id,
                    quantity: c.quantity,
                    price_per_item: c.price_per_item
                };
            }));

            const paymentMethodsData = await fetch(`${API_URL}/checkout/paymentmethods`, {
                method: "GET",
                credentials: "include"
            }).then(r => r.json());
            setPaymentMethods(paymentMethodsData.payment_methods);

            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred while fetching your cart, please try again later or contact support if this error persists");
        };
    };

    useEffect(() => {
        updateCartPage();
    }, []);

    const checkout = async() => {
        setLoaded(false);
        try {
            let guest_email = guestCheckout ? guestEmail : user.email;
            let payment_method = paymentMethod;
            let use_account_balance = useAccountBalance;

            const data = await fetch(`${API_URL}/checkout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    guest_email,
                    payment_method,
                    use_account_balance,
                    coupon: couponCode
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setLoaded(true);
                setError(data.response || data.message);
                return;
            };

            if(data.payment_already_settled === true) {
                navigate(`/user/orders/${data.order_id}`);
            } else {
                navigate(data.checkout_url);
            };
        } catch(e) {
            setLoaded(true);
            console.log(e);
            setError("An unknown error occurred while creating your checkout session, please try again later or contact support if this error persists");
        }
    };

    if(error !== false) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading />;

    return (
        <div className="storefront-cart-pg">
            <div className="storefront-cart-content">
                
                <div className="storefront-cart-details">
                    <SectionTitle
                        title={"Your Cart Details"}
                        icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/bulk/shopping-cart">
                        <g id="shopping-cart">
                        <path id="Vector" d="M14.8957 20.625C15.7816 20.625 16.4998 19.9068 16.4998 19.0208C16.4998 18.1349 15.7816 17.4167 14.8957 17.4167C14.0097 17.4167 13.2915 18.1349 13.2915 19.0208C13.2915 19.9068 14.0097 20.625 14.8957 20.625Z" fill="white"/>
                        <path id="Vector_2" d="M7.56266 20.625C8.44862 20.625 9.16683 19.9068 9.16683 19.0208C9.16683 18.1349 8.44862 17.4167 7.56266 17.4167C6.67671 17.4167 5.9585 18.1349 5.9585 19.0208C5.9585 19.9068 6.67671 20.625 7.56266 20.625Z" fill="white"/>
                        <path id="Vector_3" opacity="0.4" d="M4.43683 3.61166L4.2535 5.85749C4.21683 6.28833 4.556 6.64583 4.98683 6.64583H19.021C19.406 6.64583 19.7268 6.35249 19.7543 5.96749C19.8735 4.34499 18.636 3.025 17.0135 3.025H5.76599C5.67432 2.62166 5.49099 2.23666 5.20682 1.91582C4.75766 1.42999 4.116 1.14583 3.456 1.14583H1.8335C1.45766 1.14583 1.146 1.45749 1.146 1.83333C1.146 2.20916 1.45766 2.52083 1.8335 2.52083H3.4285C3.71267 2.52083 3.9785 2.64 4.171 2.84167C4.3635 3.0525 4.45516 3.3275 4.43683 3.61166Z" fill="white"/>
                        <path id="Vector_4" d="M18.8007 8.02083H4.73906C4.35406 8.02083 4.04239 8.31416 4.00572 8.68999L3.67572 12.6775C3.54739 14.2358 4.77572 15.5833 6.34322 15.5833H16.5366C17.9116 15.5833 19.1216 14.4558 19.2224 13.0808L19.5249 8.8C19.5616 8.37833 19.2316 8.02083 18.8007 8.02083Z" fill="white"/>
                        </g>
                        </g>
                        </svg>}
                    />

                    {cart.length > 0 
                    ? <>
                        {cart.map((cartItem, index) => {
                            return <CartItem key={`cartitem-${index}-${Math.random.toString()}`} updateCart={updateCart} product_id={cartItem.product_id} image={cartItem.image} parent_name={cartItem.parent_name} name={cartItem.name} price_per_item={cartItem.price_per_item} min_quantity={cartItem.min_quantity} quantity={cartItem.quantity} updateCartPage={() => updateCartPage()} />;
                        })}
                    </>
                    : <div className="cart-empty">
                        <div className="storefront-error">
                            <div className="cart-empty-icon"><FontAwesomeIcon icon={faBasketShopping} /></div>
                            <div className="cart-empty-text">Your cart is empty!</div>
                            <button className="btn btn-nrm btn-nrm-sm mt-2" onClick={() => navigate("/")}><FontAwesomeIcon icon={faShoppingCart} />Go shopping</button>
                        </div>
                    </div>}
                </div>

                <div className="storefront-cart-delivery">
                    <SectionTitle
                        title={"Order Delivery Details"}
                        icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/bulk/direct">
                        <g id="direct">
                        <path id="Vector" opacity="0.4" d="M14.841 1.83334H7.15933C3.82266 1.83334 1.8335 3.82251 1.8335 7.15918V14.8317C1.8335 18.1775 3.82266 20.1667 7.15933 20.1667H14.8318C18.1685 20.1667 20.1577 18.1775 20.1577 14.8408V7.15918C20.1668 3.82251 18.1777 1.83334 14.841 1.83334Z" fill="white"/>
                        <path id="Vector_2" d="M19.5252 11.2108H16.3352C15.4368 11.2108 14.6393 11.7058 14.236 12.5125L13.466 14.0342C13.2827 14.4008 12.916 14.63 12.5127 14.63H9.506C9.22183 14.63 8.8185 14.5658 8.55266 14.0342L7.78266 12.5217C7.37933 11.7242 6.57266 11.22 5.6835 11.22H2.47516C2.11766 11.22 1.8335 11.5042 1.8335 11.8617V14.85C1.8335 18.1775 3.83183 20.1667 7.1685 20.1667H14.8502C17.9943 20.1667 19.9285 18.4433 20.1668 15.3817V11.8525C20.1668 11.5042 19.8827 11.2108 19.5252 11.2108Z" fill="white"/>
                        <path id="Vector_3" d="M12.4208 7.15H9.57917C9.22167 7.15 8.9375 6.86584 8.9375 6.50834C8.9375 6.15084 9.22167 5.86667 9.57917 5.86667H12.4208C12.7783 5.86667 13.0625 6.15084 13.0625 6.50834C13.0625 6.86584 12.7692 7.15 12.4208 7.15Z" fill="white"/>
                        <path id="Vector_4" d="M13.136 9.7075H8.86432C8.50682 9.7075 8.22266 9.42333 8.22266 9.06583C8.22266 8.70833 8.50682 8.42416 8.86432 8.42416H13.1268C13.4843 8.42416 13.7685 8.70833 13.7685 9.06583C13.7685 9.42333 13.4843 9.7075 13.136 9.7075Z" fill="white"/>
                        </g>
                        </g>
                        </svg>}
                    />

                    {guestCheckout ? <div className="checkout-new-guest-checkout-delivery">
                        <div className="checkout-new-guest-email">
                            <div className="checkout-new-title">Your Email Address</div>
                            <div className="checkout-new-input">
                                <div className="checkout-new-input-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="vuesax/bulk/sms">
                                            <g id="sms">
                                                <path id="Vector" opacity="0.4" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" fill="#1675FF"/>
                                            <g id="Group">
                                                <path id="Vector_2" d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill="#1675FF"/>
                                            </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <input className="checkout-new-input-field" value={guestEmail} onChange={(e) => setGuestEmail(e.target.value)} type="text" placeholder="Email address" />
                            </div>
                        </div>
                        <div className="checkout-new-guest-divider">
                            OR
                        </div>
                        <div className="checkout-new-guest-auth-prompt">
                            <div className="checkout-new-guest-auth-prompt-txt">Sign in for a quicker checkout</div>
                            <div className="checkout-new-guest-auth-btns">
                                <Link to="/user/login"><button className="btn btn-snb btn-nav btn-nav-secondary">Log In</button></Link>
                                <Link to="/user/register"><button className="btn btn-nav btn-snb">Create Account</button></Link>
                            </div>
                        </div>
                    </div>
                    : <div className="checkout-new-user-checkout-delivery">
                        <div className="checkout-new-guest-email">
                            <div className="checkout-new-title">Your Email Address</div>
                            <div className="checkout-new-user-email">
                                <div className="checkout-new-input checkout-new-input-disabled">
                                    <div className="checkout-new-input-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="vuesax/bulk/sms">
                                                <g id="sms">
                                                    <path id="Vector" opacity="0.4" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" fill="#1675FF"/>
                                                <g id="Group">
                                                    <path id="Vector_2" d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill="#1675FF"/>
                                                </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <input className="checkout-new-input-field" placeholder="Email Address" value={user.email} disabled={true} />
                                </div>
                                <Link className="checkout-logout" to="/user/logout">Logout</Link>
                            </div>
                        </div>
                    </div>}

                    
                </div>

                <div className="storefront-cart-payment">
                    <SectionTitle
                        title={"Payment Method"}
                        icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/bulk/dollar-square">
                        <g id="dollar-square">
                        <path id="Vector" opacity="0.4" d="M14.841 1.83331H7.15933C3.82266 1.83331 1.8335 3.82248 1.8335 7.15915V14.8316C1.8335 18.1775 3.82266 20.1666 7.15933 20.1666H14.8318C18.1685 20.1666 20.1577 18.1775 20.1577 14.8408V7.15915C20.1668 3.82248 18.1777 1.83331 14.841 1.83331Z" fill="white"/>
                        <path id="Vector_2" d="M13.0718 11L11.6876 10.5142V7.40667H12.0176C12.7601 7.40667 13.3651 8.0575 13.3651 8.855C13.3651 9.23083 13.6768 9.5425 14.0526 9.5425C14.4285 9.5425 14.7401 9.23083 14.7401 8.855C14.7401 7.29667 13.521 6.03167 12.0176 6.03167H11.6876V5.5C11.6876 5.12417 11.376 4.8125 11.0001 4.8125C10.6243 4.8125 10.3126 5.12417 10.3126 5.5V6.03167H9.71681C8.36014 6.03167 7.25098 7.1775 7.25098 8.58C7.25098 10.2208 8.20431 10.7433 8.92848 11L10.3126 11.4858V14.5842H9.98264C9.24014 14.5842 8.63514 13.9333 8.63514 13.1358C8.63514 12.76 8.32348 12.4483 7.94764 12.4483C7.57181 12.4483 7.26014 12.76 7.26014 13.1358C7.26014 14.6942 8.47931 15.9592 9.98264 15.9592H10.3126V16.5C10.3126 16.8758 10.6243 17.1875 11.0001 17.1875C11.376 17.1875 11.6876 16.8758 11.6876 16.5V15.9683H12.2835C13.6401 15.9683 14.7493 14.8225 14.7493 13.42C14.7401 11.77 13.7868 11.2475 13.0718 11ZM9.38681 9.7075C8.91931 9.5425 8.63514 9.38667 8.63514 8.58917C8.63514 7.93833 9.12098 7.41583 9.72598 7.41583H10.3218V10.0375L9.38681 9.7075ZM12.2835 14.5933H11.6876V11.9717L12.6135 12.2925C13.081 12.4575 13.3651 12.6133 13.3651 13.4108C13.3651 14.0617 12.8793 14.5933 12.2835 14.5933Z" fill="white"/>
                        </g>
                        </g>
                        </svg>}
                    />

                    <div className="user-checkout-order-payment-methods">
                        {paymentMethods.map((payment_method, index) => {
                            return (
                                <div key={`user-checkout-order-payment-method-${payment_method.id}`} className={`user-checkout-order-payment-method ${paymentMethod === payment_method.id ? "user-balance-order-payment-method-selected" : null}`} onClick={() => setPaymentMethod(payment_method.id)}>
                                    <div className="user-balance-order-payment-method-txt">{payment_method.name}</div>
                                </div>
                            )
                        })}
                    </div>

                    {/* {!guestCheckout
                    ? 
                    <div className="storefront-checkout-use-account-balance">
                        <label>Use account balance <b>(${user.balance.toFixed(2)})</b></label>
                        <input type="checkbox" defaultChecked={useAccountBalance} onChange={(e) => setUseAccountBalance(e.target.checked)} />
                    </div>
                    : null } */}
                </div>

            </div>
            <div className="storefront-checkout">
                <div className="coupon-summary">
                    <div className="coupon-summary-title">Apply Coupon Code</div>

                    {couponError !== false ? <div className="auth-form-error">{couponError}</div> : null}

                    {couponDiscount > 0 ? <div className="coupon-input coupon-input-applied">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0354 9.29795C21.3771 9.63966 21.3771 10.1937 21.0354 10.5354L12.8688 18.7021C12.5271 19.0438 11.973 19.0438 11.6313 18.7021L6.96466 14.0354C6.62295 13.6937 6.62295 13.1397 6.96466 12.798C7.30636 12.4562 7.86038 12.4562 8.20209 12.798L12.25 16.8459L19.798 9.29795C20.1397 8.95624 20.6937 8.95624 21.0354 9.29795Z" fill="#1AD77A"/>
                        </svg>
                        <input className="coupon-input-field" placeholder="Coupon Code" disabled={true} defaultValue={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                        <button className="btn coupon-input-btn coupon-input-btn-remove" onClick={() => {
                            setCouponCode(null);
                            setCouponDiscount(0);
                        }}>Remove</button>
                    </div> : <div className="coupon-input">
                        <input className="coupon-input-field" placeholder="Coupon Code" defaultValue={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                        <button className="btn coupon-input-btn" disabled={!couponLoaded} onClick={() => applyCouponCode()}>Apply</button>
                    </div>}
                    {couponDiscount > 0 ? <div className="coupon-discount">
                        <div className="coupon-discount-title">Discount Applied:</div>
                        <div className="coupon-discount-value">{((couponDiscount / 1) * 100)}% off</div>
                    </div> : null}
                </div>

                <div className="checkout-summary">
                    <div className="checkout-summary-title">Order Details</div>

                    <div className="checkout-summary-content">
                        <div className="checkout-summary-text">
                            <div>Subtotal</div>
                            <div>${cartTotal.toFixed(2)}</div>
                        </div>
                        <div className="checkout-summary-text">
                            <div>Discount</div>
                            <div className="grn">-${discountAmount.toFixed(2)}</div>
                        </div>
                        {useAccountBalance && !guestCheckout
                        ? <div className="checkout-summary-text">
                            <div>Account Balance</div>
                            <div>-${user.balance >= (cartTotal - discountAmount) ? (cartTotal - discountAmount).toFixed(2) : user.balance > 0 ? ((cartTotal - discountAmount) - user.balance).toFixed(2) : (0).toFixed(2)}</div>
                        </div> : null}
                    </div>
                    <div className="checkout-summary-text checkout-final-summary-text">
                        <div>Total</div>
                        <div>${(cartTotal - discountAmount - (useAccountBalance ? user.balance >= (cartTotal - discountAmount) ? (cartTotal - discountAmount) : user.balance > 0 ? ((cartTotal - discountAmount) - user.balance) : (0) : 0)).toFixed(2)}</div>
                    </div>
                </div>

                <div className="checkout-complete">
                    <button className="btn btn-nav checkout-submit-btn" disabled={!(paymentMethod !== null && cart.length > 0) || !(guestCheckout === false || (guestEmail !== null && guestEmail.length > 0))} onClick={() => checkout()}>
                        Proceed to pay ${(cartTotal - discountAmount - (useAccountBalance ? user.balance >= (cartTotal - discountAmount) ? (cartTotal - discountAmount) : user.balance > 0 ? ((cartTotal - discountAmount) - user.balance) : (0) : 0)).toFixed(2)}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4697 8.53033C13.1768 8.23744 13.1768 7.76256 13.4697 7.46967C13.7626 7.17678 14.2374 7.17678 14.5303 7.46967L18.5303 11.4697C18.8232 11.7626 18.8232 12.2374 18.5303 12.5303L14.5303 16.5303C14.2374 16.8232 13.7626 16.8232 13.4697 16.5303C13.1768 16.2374 13.1768 15.7626 13.4697 15.4697L16.1893 12.75H6.5C6.08579 12.75 5.75 12.4142 5.75 12C5.75 11.5858 6.08579 11.25 6.5 11.25H16.1893L13.4697 8.53033Z" fill="white"/>
                        </svg>
                    </button>
                    <div className="auth-form-termstext">By purchasing, you agree to our<br /><Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link></div>
                </div>
            </div>
        </div>
    )
};

export default Cart;