import { useEffect, useState } from "react";

import StorefrontItem from "../partials/item";

import StorefrontError from "../partials/error";
import StorefrontLoading from "../partials/loading";
import { API_URL, TELEGRAM_CHANNEL_URL } from "../../../config";

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

const IndexHero = () => {
    return (
        <div className="storefront-index-hero">
            <div className="storefront-index-hero-left">
                <div className="storefront-index-hero-welcome-text">
                    <div className="storefront-txt-sx5">Welcome To</div>
                    <div className="storefront-ihero-welcome-text2">
                        <svg width="277" height="67" viewBox="0 0 277 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5662 0H69.891L65.5929 15.8843H31.4883L28.4983 27.0033H59.3326L55.3148 42.0467H24.4805L18.2202 65.406H0L17.5662 0Z" fill="white"/>
                            <path d="M75.7248 0H93.9451L80.677 49.5217H111.885L107.587 65.406H58.1586L75.7248 0Z" fill="white"/>
                            <path d="M142.616 66.6207C134.144 66.6207 127.604 64.6273 122.994 60.6407C118.447 56.5918 116.173 50.9544 116.173 43.7286C116.173 42.109 116.298 40.4271 116.547 38.683C116.796 36.8765 117.17 35.0701 117.668 33.2636L126.638 0H144.952L135.702 34.5717C135.39 35.6307 135.141 36.6585 134.954 37.6552C134.83 38.6518 134.767 39.6796 134.767 40.7386C134.767 43.7286 135.639 46.0645 137.384 47.7464C139.128 49.4282 141.65 50.2692 144.952 50.2692C148.503 50.2692 151.43 49.1168 153.735 46.812C154.981 45.6285 156.04 44.1958 156.912 42.5139C157.784 40.7697 158.563 38.5895 159.248 35.9733L168.965 0H187.279L176.907 38.683C174.603 47.2169 171.208 53.7263 166.723 58.2113C163.795 61.139 160.307 63.2881 156.258 64.6585C152.209 65.9666 147.662 66.6207 142.616 66.6207Z" fill="white"/>
                            <path d="M195.768 0H213.895L196.329 65.406H178.202L195.768 0Z" fill="white"/>
                            <path d="M233.824 49.3348C237.624 49.3348 241.143 48.7742 244.383 47.6529C247.622 46.5317 250.331 44.881 252.512 42.7008C254.256 40.9566 255.626 38.901 256.623 36.5339C257.62 34.1668 258.118 31.6752 258.118 29.0589C258.118 27.0656 257.838 25.3526 257.277 23.9199C256.716 22.4249 255.907 21.1479 254.848 20.089C253.602 18.8432 251.951 17.8776 249.895 17.1924C247.84 16.4449 245.224 16.0712 242.047 16.0712H236.814L227.844 49.3348H233.824ZM222.892 0H244.009C250.363 0 255.595 0.778644 259.706 2.33593C263.818 3.89322 267.119 5.91769 269.611 8.40934C274.345 13.1435 276.712 19.5284 276.712 27.564C276.712 32.485 275.809 37.1257 274.002 41.4861C272.196 45.8465 269.517 49.802 265.967 53.3526C262.042 57.277 257.246 60.2669 251.577 62.3226C245.909 64.3782 239.275 65.406 231.675 65.406H205.326L222.892 0Z" fill="white"/>
                        </svg>
                        <svg width="98" height="20" viewBox="0 0 98 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="stre-text">
                            <path d="M9.46627 19.9733C7.60322 19.9733 5.92105 19.6748 4.41975 19.0779C2.93655 18.4629 1.67945 17.6038 0.648438 16.5004L4.03991 13.0004C5.90296 14.7188 7.85645 15.5779 9.90037 15.5779C10.5334 15.5779 11.049 15.4513 11.4469 15.1981C11.8448 14.9449 12.0438 14.6012 12.0438 14.1671C12.0438 13.9862 11.9986 13.8234 11.9081 13.6787C11.8358 13.5159 11.682 13.3622 11.4469 13.2175C11.2117 13.0547 10.8862 12.8828 10.4701 12.702C10.0722 12.503 9.53862 12.2769 8.86937 12.0237C8.10968 11.7343 7.42234 11.4358 6.80735 11.1283C6.21045 10.8027 5.69495 10.441 5.26084 10.043C4.84482 9.62702 4.51924 9.15674 4.2841 8.63219C4.04895 8.10764 3.93138 7.49266 3.93138 6.78723C3.93138 5.84666 4.13035 4.97845 4.52828 4.18258C4.9443 3.38671 5.50503 2.70842 6.21045 2.1477C6.93397 1.56888 7.7841 1.12573 8.76084 0.81824C9.75567 0.492658 10.8319 0.329867 11.9895 0.329867C13.6717 0.329867 15.1549 0.583097 16.4391 1.08956C17.7415 1.59602 18.8539 2.28336 19.7763 3.15157L16.5205 6.78723C15.797 6.15416 15.0192 5.6477 14.1872 5.26785C13.3732 4.88801 12.4779 4.69808 11.5011 4.69808C10.8681 4.69808 10.3616 4.83374 9.98177 5.10506C9.62001 5.35829 9.43913 5.67483 9.43913 6.05467C9.43913 6.25364 9.48435 6.42547 9.57479 6.57018C9.66523 6.69679 9.81898 6.83245 10.036 6.97715C10.2712 7.12186 10.5968 7.28465 11.0128 7.46553C11.4288 7.6464 11.9624 7.86346 12.6136 8.11669C13.3371 8.38801 13.9973 8.68646 14.5942 9.01204C15.2092 9.33762 15.7337 9.70842 16.1678 10.1244C16.6019 10.5224 16.9365 10.9927 17.1717 11.5353C17.4249 12.0779 17.5515 12.702 17.5515 13.4074C17.5515 14.3841 17.3526 15.2795 16.9546 16.0934C16.5567 16.8893 15.996 17.5766 15.2725 18.1554C14.567 18.7343 13.7169 19.1865 12.7221 19.512C11.7272 19.8195 10.642 19.9733 9.46627 19.9733Z" fill="white"/>
                            <path d="M26.7365 5.26785H21.0388L22.2869 0.655448H38.9187L37.6977 5.26785H32.0001L28.1473 19.6477H22.8566L26.7365 5.26785Z" fill="white"/>
                            <path d="M47.1811 15.2795C48.0132 15.2795 48.7638 15.0986 49.4331 14.7368C50.1204 14.3751 50.6992 13.9048 51.1695 13.326C51.6398 12.7291 52.0015 12.0779 52.2548 11.3725C52.5261 10.649 52.6617 9.93452 52.6617 9.22909C52.6617 8.63219 52.5713 8.08051 52.3904 7.57405C52.2096 7.0495 51.9382 6.59731 51.5765 6.21746C51.2328 5.83762 50.7987 5.54821 50.2742 5.34925C49.7677 5.13219 49.1798 5.02366 48.5106 5.02366C47.6785 5.02366 46.9279 5.20454 46.2586 5.5663C45.5894 5.92806 45.0106 6.40739 44.5222 7.00429C44.0519 7.5831 43.6811 8.23426 43.4098 8.95777C43.1566 9.6632 43.03 10.3686 43.03 11.0741C43.03 11.671 43.1204 12.2317 43.3013 12.7562C43.4822 13.2627 43.7444 13.7058 44.0881 14.0857C44.4499 14.4655 44.884 14.764 45.3904 14.981C45.915 15.18 46.5119 15.2795 47.1811 15.2795ZM46.7199 20.0004C45.3633 20.0004 44.1243 19.7834 43.0028 19.3492C41.8995 18.9151 40.9499 18.3182 40.154 17.5585C39.3581 16.7989 38.7431 15.9035 38.309 14.8725C37.8749 13.8234 37.6579 12.6929 37.6579 11.481C37.6579 10.034 37.9382 8.64124 38.499 7.30274C39.0597 5.94615 39.8375 4.75235 40.8323 3.72134C41.8452 2.69033 43.039 1.86733 44.4137 1.25235C45.8065 0.619272 47.3258 0.302734 48.9718 0.302734C50.3284 0.302734 51.5584 0.519789 52.6617 0.953898C53.7832 1.38801 54.7419 1.98491 55.5377 2.7446C56.3336 3.50429 56.9486 4.40868 57.3827 5.45777C57.8168 6.48878 58.0338 7.61023 58.0338 8.82211C58.0338 10.2691 57.7535 11.671 57.1928 13.0275C56.632 14.366 55.8452 15.5508 54.8323 16.5818C53.8375 17.6128 52.6437 18.4449 51.2509 19.0779C49.8762 19.6929 48.3659 20.0004 46.7199 20.0004Z" fill="white"/>
                            <path d="M68.9266 9.77173C70.1204 9.77173 71.07 9.53658 71.7755 9.0663C72.4809 8.59602 72.8336 7.9539 72.8336 7.13994C72.8336 6.65157 72.6708 6.22651 72.3452 5.86475C71.9111 5.43064 71.1333 5.21359 70.0119 5.21359H67.163L65.915 9.77173H68.9266ZM63.0933 0.655448H70.2561C71.8478 0.655448 73.1773 0.827282 74.2444 1.17095C75.3116 1.51462 76.1618 2.00299 76.7948 2.63607C77.3013 3.14253 77.6721 3.73038 77.9072 4.39963C78.1424 5.0508 78.2599 5.75622 78.2599 6.51591C78.2599 8.10764 77.7987 9.50041 76.8762 10.6942C75.9537 11.8699 74.5158 12.72 72.5623 13.2446L75.4925 19.6477H69.6863L67.163 13.8958H64.8297L63.2832 19.6477H57.9925L63.0933 0.655448Z" fill="white"/>
                            <path d="M82.6207 0.655448H97.8417L96.6207 5.13219H86.6362L85.8494 8.00816H94.9114L93.799 12.1593H84.737L83.9231 15.171H94.0704L92.8765 19.6477H77.52L82.6207 0.655448Z" fill="white"/>
                        </svg>
                    </div>
                </div>           
            </div>
            <div className="storefront-index-hero-right">
                <div className="storefront-index-hero-p">
                    Your #1 Store for all Giftcards, Accounts and Email Bomber
                </div>
                <button className="btn lg-white-btn" onClick={() => window.open(TELEGRAM_CHANNEL_URL, "_blank")}>
                    View our Reviews here
                </button>
            </div>
        </div>
    )
};

const Index = () => {
    const [announcement, setAnnouncement] = useState(null);
    const [productCategories, setProductCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [currentPage, setCurrentPageF] = useState(0);
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [visibleProductCategoryChunks, setVisibleProductCategoryChunks] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const data = await fetch(`${API_URL}/products`, {
                    method: "GET",
                    credentials: "include"
                }).then(r => r.json());
                if(!data.success) return setError(data.response || data.message);
                
                // setProductCategoryChunks(sliceIntoChunks(data.product_categories, 4));
                setProductCategories(data.product_categories);
                setVisibleProductCategoryChunks(sliceIntoChunks(data.product_categories.slice(0, 20), window.innerWidth > 850 ? 4 : 2));
                setTags(data.tags);

                if(data.announcement.published === true && data.announcement.content !== null) {
                    console.log(data.announcement);
                    setAnnouncement(data.announcement.content);
                };

                setLoaded(true);
            } catch(e) {
                setError("An unknown error occurred, please try again later or contact support if this error persists")
            };
        };
        fetchData();
    }, []);

    // useEffect(() => {
    //     setVisibleProductCategoryChunks(productCategoryChunks);
    // }, [productCategoryChunks]);

    const filterProducts = (search) => {
        setVisibleProductCategoryChunks([]);
        if(!search.length > 0) return setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.slice(20 * currentPage, (20 * currentPage) + 20), window.innerWidth > 850 ? 4 : 2));
        setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.filter(pc => pc.category_name.toLowerCase().includes(search.toLowerCase())), window.innerWidth > 850 ? 4 : 2));
    };

    const filterProductsByTag = (tag_id) => {
        setVisibleProductCategoryChunks([]);
        if(tag_id === "all") return setVisibleProductCategoryChunks(sliceIntoChunks(productCategories, window.innerWidth > 850 ? 4 : 2));
        setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.filter(pc => pc.category_tags.findIndex(t => t.tag_id === tag_id) !== -1), window.innerWidth > 850 ? 4 : 2));
    };

    const setCurrentPage = (page) => {
        setVisibleProductCategoryChunks([]);
        setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.slice(20 * page, (20 * page) + 20), window.innerWidth > 850 ? 4 : 2));
        setCurrentPageF(page);
    };

    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return (
        <div className="storefront-index">
            <IndexHero />
            <StorefrontLoading />
            <div className="storefront-items" style={{ marginTop: "128px" }}></div>
        </div>
    );

    return (
        <div className="storefront-index">
            {announcement !== null ? <div className="storefront-cont"><div className="storefront-announcement">{announcement}</div></div> : null}

            <IndexHero />
            <div className="storefront-index-search">
                <div className="storefront-search-container">
                    <div className="storefront-input-container">
                        <div className="storefront-input-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3849 15.446C11.7346 17.5687 7.8552 17.4016 5.39842 14.9448C2.76238 12.3088 2.76238 8.03494 5.39842 5.3989C8.03445 2.76287 12.3083 2.76287 14.9444 5.3989C17.4011 7.85569 17.5682 11.7351 15.4456 14.3854L20.6012 19.541C20.8941 19.8339 20.8941 20.3088 20.6012 20.6017C20.3083 20.8946 19.8334 20.8946 19.5405 20.6017L14.3849 15.446ZM6.45908 13.8842C4.40882 11.8339 4.40882 8.50982 6.45908 6.45956C8.50933 4.40931 11.8334 4.40931 13.8837 6.45956C15.9324 8.50831 15.9339 11.8291 13.8882 13.8797C13.8867 13.8812 13.8852 13.8827 13.8837 13.8842C13.8822 13.8857 13.8807 13.8872 13.8792 13.8887C11.8286 15.9344 8.50783 15.9329 6.45908 13.8842Z" fill="white"/>
                            </svg>
                        </div>
                        <input className="storefront-input" placeholder="Search for products here" onChange={(e) => filterProducts(e.target.value)} />
                    </div>

                    <div className="storefront-input-container">
                        <div className="storefront-input-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="pf-theme">
                                <path d="M7.55675 2.68997C6.4205 2.56298 5.2463 2.56298 4.11006 2.68997C3.37907 2.77167 2.78799 3.3473 2.70178 4.08433C2.56588 5.24634 2.56588 6.42024 2.70178 7.58225C2.78799 8.31928 3.37907 8.89491 4.11006 8.97661C5.2463 9.1036 6.4205 9.1036 7.55675 8.97661C8.28773 8.89491 8.87882 8.31928 8.96502 7.58225C9.10093 6.42024 9.10093 5.24634 8.96502 4.08433C8.87882 3.3473 8.28773 2.77167 7.55675 2.68997Z"></path>
                                <path d="M7.55675 11.0233C6.4205 10.8963 5.2463 10.8963 4.11006 11.0233C3.37907 11.105 2.78799 11.6806 2.70178 12.4177C2.56588 13.5797 2.56588 14.7536 2.70178 15.9156C2.78799 16.6526 3.37907 17.2282 4.11006 17.3099C5.2463 17.4369 6.4205 17.4369 7.55675 17.3099C8.28773 17.2282 8.87882 16.6526 8.96502 15.9156C9.10093 14.7536 9.10093 13.5797 8.96502 12.4177C8.87882 11.6806 8.28773 11.105 7.55675 11.0233Z"></path>
                                <path d="M15.8901 2.68997C14.7538 2.56298 13.5796 2.56298 12.4434 2.68997C11.7124 2.77167 11.1213 3.3473 11.0351 4.08433C10.8992 5.24634 10.8992 6.42024 11.0351 7.58225C11.1213 8.31928 11.7124 8.89491 12.4434 8.97661C13.5796 9.1036 14.7538 9.1036 15.8901 8.97661C16.6211 8.89491 17.2122 8.31928 17.2984 7.58225C17.4343 6.42024 17.4343 5.24634 17.2984 4.08433C17.2122 3.3473 16.6211 2.77167 15.8901 2.68997Z"></path>
                                <path d="M15.8901 11.0233C14.7538 10.8963 13.5796 10.8963 12.4434 11.0233C11.7124 11.105 11.1213 11.6806 11.0351 12.4177C10.8992 13.5797 10.8992 14.7536 11.0351 15.9156C11.1213 16.6526 11.7124 17.2282 12.4434 17.3099C13.5796 17.4369 14.7538 17.4369 15.8901 17.3099C16.6211 17.2282 17.2122 16.6526 17.2984 15.9156C17.4343 14.7536 17.4343 13.5797 17.2984 12.4177C17.2122 11.6806 16.6211 11.105 15.8901 11.0233Z"></path>
                            </svg>                  
                        </div>
                        <select className="select storefront-input" onChange={(e) => filterProductsByTag(e.target.value)}>
                            <option value="all">All Categories</option>
                            {tags.map((tag, index) => (
                                <option value={tag.tag_id} key={`product-tag-option-${tag.tag_id}-${index}`}>{tag.name}</option>
                            ))}
                        </select>
                    </div>
                    

                </div>
            </div>

            <div className="storefront-items">
                {visibleProductCategoryChunks.map((product_categories_chunk, index) => {
                    return (
                        <div className="storefront-items-row" key={`product-category-chunk-${index}`}>
                            {product_categories_chunk.map((product_category) => {
                                return (
                                    <StorefrontItem key={`storefront-item-category-${product_category.category_id}`} category_id={product_category.category_id} name={product_category.category_name} description={product_category.category_description} image={product_category.category_image_url} tags={product_category.category_tags} products={product_category.products} />
                                )
                            })}
                        </div>
                    )
                })}
            </div>

            <div className="storefront-pages">
                <div className="storefront-pagnation-buttons">
                    {currentPage > 0 ? <button className={`btn btn-pag`} onClick={() => setCurrentPage(currentPage - 1)}>prev</button> : null}
                    {Array(Math.ceil(productCategories.length / 20)).fill(1).map((page, index) => {
                        return <button key={`storefront-page-btn-${index}`} className={`btn btn-pag ${currentPage === index ? "btn-pag-active" : ""}`} onClick={() => setCurrentPage(index)}>{index + 1}</button>
                    })}
                    {currentPage < (productCategories.length / 20) - 1 ? <button className={`btn btn-pag`} onClick={() => setCurrentPage(currentPage + 1)}>next</button> : null}
                </div>
            </div>
        </div>
    )
};

export default Index;