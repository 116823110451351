import { useContext, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY } from "../../../config";

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const captchaRef = useRef();
    const navigate = useNavigate();

    const submit = async() => {
        try {
            const data = await fetch(`${API_URL}/auth/resetpassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email,
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCaptcha(null);
                setError(data.response || data.message);
                captchaRef.current?.reset();
                return;
            };

            setSuccess(data.response);
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    return (
        <div className="auth-form-page">
            <div className="auth-form">
                <div className="auth-form-title">Reset Password</div>
                <div className="auth-form-subtitle">Enter your registered email address</div>

                {success !== false ? <div className="form-info">{success}</div> : null}
                {error !== false ? <div className="auth-form-error">{error}</div> : null}

                <div className="auth-form-inputs">
                    <div className="auth-input">
                        <div className="auth-input-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="vuesax/bulk/sms">
                                    <g id="sms">
                                        <path id="Vector" opacity="0.4" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" fill="white"/>
                                    <g id="Group">
                                        <path id="Vector_2" d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill="white"/>
                                    </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <input className="auth-input-field" placeholder="Email" type="text" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <div className="auth-form-captcha">
                    <Turnstile siteKey={CLOUDFLARE_TURNSTILE_SITEKEY} ref={captchaRef} onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} />
                </div>

                <button className="btn auth-form-btn" disabled={captcha === null} onClick={() => submit()}>Reset Password</button>
            </div>
            <div className="auth-form-blw">
                <div className="auth-form-sub"><span>Already have an account?</span> <Link to="/user/login">Login</Link></div>
            </div>
        </div>
    );
};

export default ResetPasswordRequest;