import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";
import { API_URL, STORE_NAME } from "../../../config";  
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const reasonTitle = {
    "account": `A problem with my ${STORE_NAME} account`,
    "order": "A problem with an order",
    "other": "Something else"
};

const SignupReason = ({ reason }) => {
    return (
        <div className="signup-reason">
            <div className="signup-reason-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Solid/Interface/Check">
                        <path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M18.0303 7.96967C18.3232 8.26256 18.3232 8.73744 18.0303 9.03033L11.0303 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="#1675FF" />
                    </g>
                </svg>
            </div>
            <div className="pw-change-reason-text">{reason}</div>
        </div>
    )
};

const TicketsTable = ({ tickets }) => {
    const naviagte = useNavigate();
    
    return (
        <table className="userorders-table usertickets-table">
            <thead>
                <tr>
                    <th>Reason</th>
                    {/* <th>Opened</th> */}
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tickets.map((ticket, index) => {
                    if(ticket.status === "closed") ticket.status = "solved";
                    return <tr key={`ticket-table-row-${ticket.ticket_id}`}>
                        <td>{reasonTitle[ticket.reason]}</td>
                        {/* <td>{new Date(ticket.created_at).toLocaleString()}</td> */}
                        <td><div className={`userorders-table-status capitalize userorders-table-status-${ticket.status.toLowerCase().replace(" ", "")}`}>{ticket.status.replaceAll("_", " ")}</div></td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => naviagte(`/user/tickets/${ticket.ticket_id}`)}>View</button></td>
                    </tr>
                })}
            </tbody>
        </table>
    );
};

const Tickets = () => {
    const [tickets, setTickets] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const data = await fetch(`${API_URL}/user/dashboard/tickets`, {
                    method: "GET",
                    credentials: "include"
                }).then(r => r.json());
                if(!data.success) {
                    setError(data.response || data.message);
                    setLoaded(true);
                    return;
                };
                setTickets(data.tickets);
                setLoaded(true);
            } catch(e) {
                console.log(e);
                setError("An unknown error occurred, please try again later!");
            }
        };
        fetchData();
    }, []);
    
    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            {/* <button className="btn btn-snb" onClick={() => navigate("/user/tickets/new")}><FontAwesomeIcon icon={faPlus} /> New Ticket</button> */}
            <div className="user-home-welcome">
                <div className="user-home-welcome-title">Support</div>
            </div>
            <div className="user-support-pg">
                <div className="user-support-new">
                    <div className="user-home-details-title">Create a support ticket</div>
                    <div className="user-support-new-text">Create a support ticket if your having any issues on our store, wether it's with your account, order or something else - we'll get your issue sorted as soon as possible</div>
                    <div className="support-reasons">
                        <SignupReason reason="Our dedicated support team is online 24/7" />
                        <SignupReason reason="Use as much detail as possible when creating your ticket" />
                        <SignupReason reason="Include any error messages - with screnshots if possible" />
                        <SignupReason reason="If your ticket is about a product not working, we'll replace it or credit your account balance with it's value" />
                        <SignupReason reason="We'll reply as soon as we can" />
                    </div>
                    <button className="btn btn-nav checkout-submit-btn" onClick={() => navigate("/user/tickets/new")}>
                        Create support ticket
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4697 8.53033C13.1768 8.23744 13.1768 7.76256 13.4697 7.46967C13.7626 7.17678 14.2374 7.17678 14.5303 7.46967L18.5303 11.4697C18.8232 11.7626 18.8232 12.2374 18.5303 12.5303L14.5303 16.5303C14.2374 16.8232 13.7626 16.8232 13.4697 16.5303C13.1768 16.2374 13.1768 15.7626 13.4697 15.4697L16.1893 12.75H6.5C6.08579 12.75 5.75 12.4142 5.75 12C5.75 11.5858 6.08579 11.25 6.5 11.25H16.1893L13.4697 8.53033Z" fill="white"/>
                        </svg>
                    </button>
                </div>
                <div className="user-support-tickets">
                    <div className="user-support-existing-title">Your Support Tickets</div>
                    <TicketsTable tickets={tickets} />
                </div>
            </div>
        </div>
    );
};

export default Tickets;