import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { API_URL } from "./config";

import "./scss/app.scss";

import UserContext from "./contexts/user";
import CartContext from "./contexts/cart";

import Storefront from "./views/storefront/layout";
import StoreIndex from "./views/storefront/pages/index";
import StoreTerms from "./views/storefront/pages/terms";
import StorePrivacy from "./views/storefront/pages/terms";
import StoreFAQ from "./views/storefront/pages/faq";
import StoreCart from "./views/storefront/pages/cart";
import StoreLogin from "./views/storefront/pages/login";
import StoreRegister from "./views/storefront/pages/register";
import StorefrontResetPassword from "./views/storefront/pages/resetpasswordrequest";
import StorefrontResetPasswordVerify from "./views/storefront/pages/resetpasswordverify";
import StoreLogout from "./views/storefront/pages/logout";
import StoreUserHome from "./views/storefront/pages/userhome";
import StoreUserBalance from "./views/storefront/pages/userbalance";
import StoreUserReferral from "./views/storefront/pages/userreferral";
import StoreUserSettings from "./views/storefront/pages/usersettings";
import StoreUserOrders from "./views/storefront/pages/userorders";
import StoreUserTickets from "./views/storefront/pages/usertickets";
import StoreUserNewTicket from "./views/storefront/pages/usernewticket";
import StoreUserViewTicket from "./views/storefront/pages/userviewticket";
import StoreTrackOrder from "./views/storefront/pages/trackorder";

const App = () => {
	const [user, setUser] = useState({
		loggedin: false,
		username: null,
		email: null,
		balance: null
	});
	const [cart, setCart] = useState([]);

	const updateCart = async() => {
		const fetchCartData = async() => {
			try {
				const data = await fetch(`${API_URL}/cart`, {
					method: "GET",
					credentials: "include"
				}).then(r => r.json());
				if(!data.success) {
					return;
				};

				if(!(data.cart.length > 0)) {
					setCart([{ product_id: null, quantity: 0, price_per_item: 0 }]);
					setTimeout(() => {
						setCart([]);
					}, 2000);
				} else {	
					setCart(data.cart.map(c => {
						return {
							product_id: c.product_id,
							quantity: c.quantity,
							price_per_item: c.price_per_item
						};
					}));
				};
			} catch(e) {
				console.log(e);
				setCart([]);
			}
		};
		fetchCartData();
	};

	useEffect(() => {
		(() => {
			const fetchData = async() => {
				try {
					const data = await fetch(`${API_URL}/session/me`, {
						method: "GET",
						credentials: "include"
					}).then(r => r.json());
					if(!data.success) {
						Cookies.remove("loggedin");
						Cookies.remove("username");
						Cookies.remove("email");
						Cookies.remove("balance");
						setUser({ loggedin: false, username: null, balance: null });
						return;
					};
					setUser({
						loggedin: true,
						username: data.user.username,
						email: data.user.email,
						balance: data.user.balance
					});
					Cookies.set("loggedin", "true")
					Cookies.set("username", data.user.username);
					Cookies.set("balance", data.user.balance);
				} catch(e) {
					console.log(e);
					setUser({ loggedin: false, username: null, balance: null });
				}
			};
			fetchData();
			if(Cookies.get("loggedin") === "true") {
				setUser({
					loggedin: true,
					username: Cookies.get("username"),
					email: Cookies.get("email"),
					balance: !isNaN(Cookies.get("balance")) ? parseInt(Cookies.get("balance")) : 0
				});
			};
		})();

		(() => {
			updateCart();

			setInterval(() => {
				updateCart();
			}, 5000);
		})();
 	}, []);

	return (
		<BrowserRouter>
			<UserContext.Provider value={{ user, setUser }}>
				<CartContext.Provider value={{ cart, setCart }}>
					<Routes>
						<Route path="/" element={<Storefront showFooter={true} />}>
							<Route index element={<StoreIndex />} />
							<Route path="terms" element={<StoreTerms />} />
							<Route path="privacy" element={<StorePrivacy />} />
							<Route path="faq" element={<StoreFAQ />} />
						</Route>
						<Route path="/" element={<Storefront showFooter={true} />}>
							<Route path="cart" element={<StoreCart updateCart={updateCart} />} />
							<Route path="user">
								<Route path="login" element={<StoreLogin />} />
								<Route path="passwordreset" element={<StorefrontResetPassword />} />
								<Route path="verifypasswordreset" element={<StorefrontResetPasswordVerify />} />
								<Route path="register" element={<StoreRegister />} />
								<Route path="logout" element={<StoreLogout />} />
							</Route>
							<Route path="orders/:order_id" element={<StoreTrackOrder />} />
							<Route path="user">
								<Route path="home" element={<StoreUserHome />} />
								<Route path="balance" element={<StoreUserBalance />} />
								<Route path="referral" element={<StoreUserReferral />} />
								<Route path="settings" element={<StoreUserSettings />} />
								<Route path="orders" element={<StoreUserOrders />} />
								<Route path="tickets">
									<Route index element={<StoreUserTickets />} />
									<Route path="new" element={<StoreUserNewTicket />} />
									<Route path=":ticket_id" element={<StoreUserViewTicket />} />
								</Route>
							</Route>
						</Route>
					</Routes>
				</CartContext.Provider>
			</UserContext.Provider>
		</BrowserRouter>
	)
};

export default App;