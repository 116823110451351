import { useContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import UserContext from "../../contexts/user";

import Navbar from "./partials/navbar";
import Footer from "./partials/footer"

const Layout = ({ showFooter }) => {
    const { user } = useContext(UserContext);

    return (
        <>
            <div className="storefront storefront-container">
                <Navbar user={user} />
                <Outlet />
            </div>
            {showFooter ? <Footer /> : null}
        </>
    )
};

export default Layout;